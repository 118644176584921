import React, { useState } from "react";
import _isEqual from "lodash.isequal";
import Loader from "../../components/Loader";
import EmailRecipientsSection from "../../components/EmailRecipientsSection";

const TravelTripSettings = (props) => {
  const {
    trip: {
      enrollment,
      enrollment: { chabadHouseID, emailRecipients: _emailRecipients },
      settings: { loading } = {},
    } = {},
    actions,
  } = props;

  const [emailRecipients, setEmailRecipients] = useState(_emailRecipients);

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [submitAttempted, setSubmitAttempted] = useState(false);

  let dirty = !_isEqual(emailRecipients, _emailRecipients);

  const onSubmit = async () => {
    setSubmitAttempted(true);
    setSubmitting(true);

    if (emailRecipients.length) {
      const response = await actions.submitTripEnrollment(true, {
        ...enrollment,
        emailRecipients,
      });

      if (response.success) {
        setSuccessMessage("Your changes have been saved");
        setErrorMessage();
        if (response?.enrollment?.emailRecipients) {
          setEmailRecipients(response?.enrollment?.emailRecipients);
        }
      } else if (response.errorMessage) {
        setErrorMessage(response.errorMessage);
      }
    }
    setSubmitting(false);
    setSubmitAttempted(false);
  };
  return loading ? (
    <Loader />
  ) : (
    <div>
      <EmailRecipientsSection
        chabadHouseID={chabadHouseID}
        emailRecipients={emailRecipients || []}
        formSectionClassName="trip-page-form-section shluchim-section"
        onChange={setEmailRecipients}
        submitAttempted={submitAttempted}
      />
      {!loading && (
        <div className="trip-page-form-btns relative">
          <div>
            <button
              className="btn btn-accent"
              onClick={onSubmit}
              disabled={!dirty}
            >
              {submitting ? "Saving..." : "Save"}
            </button>
            {dirty && !errorMessage ? (
              <p className="accent-text-dark xs-text flex flex-align-center ml-16 mt-8">
                <i className="material-icons medium-text mr-8">save</i>
                Unsaved changes
              </p>
            ) : (
              <p
                className="accent-text-dark xs-text flex flex-align-center ml-16 mt-8 line-height-double"
                style={{ maxWidth: "104px" }}
              >
                {successMessage ? (
                  <i className="material-icons medium-text mr-8">check</i>
                ) : null}
                {successMessage}
              </p>
            )}
            {errorMessage && (
              <p className="error-message fw-600">{errorMessage}</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
export default React.memo(TravelTripSettings);
