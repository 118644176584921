import React, { useState } from "react";
import { SystemSelectors } from "../../../state";
import Loader from "../../../components/Loader";
import {
  formatNumber,
  isMobileView,
  isTabletView,
  Navigation,
  pluralizeText,
  sum,
} from "../../../lib";
import _cloneDeep from "lodash.clonedeep";
import { Divider, Tooltip } from "@material-ui/core";
import { shallowEqual, useSelector } from "react-redux";

export default function EngagementPeriodGoals(props) {
  const {
    engagementPeriodGoals,
    errorMessage,
    hasUnsavedChanges,
    isCurrentEngagementPeriod,
    jewishStudentCount,
    loading,
    onCancelChanges,
    onChange,
    submitErrorMessage,
    submitGoals,
    submitLoading,
  } = props;

  const [expandedCategoryIds, setExpandedCategoryIds] = useState([]);

  const jewishInterestLevels = useSelector(
    SystemSelectors.engagementJewishInterestLevels,
  );
  const interactionCategories = useSelector(
    SystemSelectors.sortedInteractionCategories,
    shallowEqual,
  );
  const interactionTypes = useSelector(SystemSelectors.interactionTypes);
  const [studentValidationObject, setStudentValidationObject] = useState({});
  const [interactionValidationObject, setInteractionValidationObject] =
    useState({});
  const onChangeStudentGoal = (name, value, index, jewishInterestLevel) => {
    if (value <= 0 && value !== "") {
      return;
    }
    if (studentValidationObject[index]) {
      const newStudentValidationObject = { ...studentValidationObject };
      delete newStudentValidationObject[index];
      setStudentValidationObject(newStudentValidationObject);
    }
    const studentGoals = _cloneDeep(engagementPeriodGoals.studentGoals);
    if (index < 0) {
      //create new goal if doesn't exist
      studentGoals.push({
        jewishInterestLevel,
        [name]: value,
      });
    } else {
      studentGoals[index][name] = value;
    }

    onChange({ ...engagementPeriodGoals, studentGoals });
  };

  const onChangeInteractionGoal = (name, value, index, interactionTypeID) => {
    if (value <= 0 && value !== "") {
      return;
    }
    if (interactionValidationObject[index]) {
      const newInteractionValidationObject = {
        ...interactionValidationObject,
      };
      delete newInteractionValidationObject[index];
      setInteractionValidationObject(newInteractionValidationObject);
    }
    const interactionGoals = _cloneDeep(engagementPeriodGoals.interactionGoals);
    if (index < 0) {
      //create new goal if doesn't exist
      interactionGoals.push({
        interactionTypeID,
        [name]: value,
      });
    } else {
      interactionGoals[index][name] = value;
    }

    onChange({ ...engagementPeriodGoals, interactionGoals });
  };

  const toggleShowHideCategory = (categoryId) => {
    const newExpandedCategoryIds =
      expandedCategoryIds.indexOf(categoryId) >= 0
        ? expandedCategoryIds.filter((id) => id !== categoryId)
        : [...expandedCategoryIds, categoryId];

    setExpandedCategoryIds(newExpandedCategoryIds);
  };

  const { didPeriodEnd, interactionGoals, studentGoals, targetedStudents } =
    engagementPeriodGoals || {};

  let studentGoalsTotal = 0,
    studentGoalsPercentage = 0;
  if (studentGoals || targetedStudents) {
    const studentGoalsInterestLevels = studentGoals
      .filter((sg) => sg.goal > 0)
      .map((sg) => sg.jewishInterestLevel);

    //'soft' goals refer to tagged students whose interest levels do not have a set goal
    const targetedStudentsSoftTotal = targetedStudents
      ? targetedStudents.filter(
          (t) => !studentGoalsInterestLevels.includes(t.targetInterestLevel),
        ).length
      : 0;
    studentGoalsTotal =
      sum(studentGoals.map((s) => s.goal)) + targetedStudentsSoftTotal;
    studentGoalsPercentage = (studentGoalsTotal * 100) / jewishStudentCount;
  }

  return loading ? (
    <Loader />
  ) : errorMessage ? (
    <div className="error-text small-text mt-24">
      Something went wrong and goal details could not be retrieved. Please try
      again.
    </div>
  ) : (
    engagementPeriodGoals && (
      <div>
        {!didPeriodEnd && !isMobileView() && !isTabletView() && (
          <div className="mb-16">
            <div className="flex flex-align-center flex-justify-end">
              <button
                className="btn btn-light btn-medium"
                disabled={submitLoading || !hasUnsavedChanges}
                style={{ padding: "0 32px" }}
                onClick={onCancelChanges}
              >
                Cancel
              </button>
              <button
                className="btn btn-accent btn-medium ml-16"
                disabled={
                  submitLoading ||
                  !hasUnsavedChanges ||
                  !!Object.keys(studentValidationObject).length ||
                  !!Object.keys(interactionValidationObject).length
                }
                style={{ padding: "0 32px" }}
                onClick={submitGoals}
              >
                {submitLoading ? "Saving..." : "Save"}
              </button>
            </div>
            {submitErrorMessage && (
              <p className="small-text error-text text-right mt-4">
                {submitErrorMessage}
              </p>
            )}
          </div>
        )}
        <div className="mb-24">
          <p className="fw-700 mb-8">Student Goals</p>
          <p className="accent-text small-text line-height-double mb-8">
            Add goals for the amount of students you intend to reach this month.
            {isCurrentEngagementPeriod && (
              <span>
                {" "}
                You can tag specific students from the{" "}
                <span
                  className="link-text underline-text pointer"
                  onClick={() => Navigation.go("/engagement/students")}
                >
                  student list
                </span>
                .
              </span>
            )}
          </p>
          <div>
            {jewishInterestLevels &&
              jewishInterestLevels.map((level, levelIndex) => {
                const goalIndex = studentGoals.findIndex(
                  (g) => g.jewishInterestLevel === level.enumValue,
                );
                const studentGoal = studentGoals[goalIndex] || {};
                const targetedStudentGoal = targetedStudents
                  ? targetedStudents.filter(
                      (t) => t.targetInterestLevel === level.enumValue,
                    ).length
                  : 0;
                return (
                  <div>
                    <div
                      className="flex flex-align-center flex-justify-space"
                      key={levelIndex}
                      style={{ marginBottom: didPeriodEnd ? "12px" : "8px" }}
                    >
                      <div>
                        <p className="fw-700">{level.displayValue}</p>
                        <p className="small-text fw-300 mt-4 mb-4 accent-text">{`${
                          studentGoal.goal && targetedStudentGoal
                            ? "Includes"
                            : ""
                        } ${targetedStudentGoal} tagged ${pluralizeText(
                          "student",
                          targetedStudentGoal,
                        )}`}</p>
                      </div>
                      {didPeriodEnd ? (
                        <p
                          className={`${
                            targetedStudentGoal && !studentGoal.goal
                              ? "accent-text fw-300"
                              : ""
                          }`}
                        >
                          {studentGoal.goal
                            ? `Goal of ${formatNumber(studentGoal.goal)} set`
                            : targetedStudentGoal
                            ? `Goal of ${formatNumber(targetedStudentGoal)} set`
                            : "No goal set"}
                        </p>
                      ) : (
                        <Tooltip
                          title={
                            !!targetedStudentGoal &&
                            !!studentGoal.goal &&
                            studentGoal.goal === targetedStudentGoal
                              ? `You cannot set the goal any lower because you have ${targetedStudentGoal} tagged ${pluralizeText(
                                  "student",
                                  targetedStudentGoal,
                                )}.`
                              : ""
                          }
                          arrow
                        >
                          <div>
                            <div>
                              <Tooltip
                                title={
                                  targetedStudentGoal && !studentGoal.goal
                                    ? "Calculated based on tagged students."
                                    : ""
                                }
                                arrow
                                placement="right"
                              >
                                <input
                                  className="custom-input"
                                  min={1}
                                  name="goal"
                                  onBlur={(e) => {
                                    const minValue = targetedStudentGoal || 1;
                                    if (
                                      e.target.value < minValue &&
                                      e.target.value !== ""
                                    ) {
                                      setStudentValidationObject({
                                        ...studentValidationObject,
                                        [goalIndex]:
                                          "You cannot set a goal lower than the number of tagged students",
                                      });
                                    }
                                  }}
                                  onChange={(e) =>
                                    onChangeStudentGoal(
                                      e.target.name,
                                      e.target.value,
                                      goalIndex,
                                      level.enumValue,
                                      targetedStudentGoal,
                                    )
                                  }
                                  style={{ width: "80px" }}
                                  type="number"
                                  value={studentGoal.goal || ""}
                                  placeholder={targetedStudentGoal || ""}
                                />
                              </Tooltip>
                            </div>
                          </div>
                        </Tooltip>
                      )}
                    </div>
                    <div className="error-text small-text mb-4">
                      {studentValidationObject[goalIndex]}
                    </div>
                  </div>
                );
              })}
            <div className="mobile-text-right">
              <p className="fw-700 mt-16 mb-8">
                Total: {formatNumber(studentGoalsTotal)}{" "}
                {pluralizeText("student", studentGoalsTotal)}
              </p>
              {!!jewishStudentCount && (
                <p className="accent-text">
                  {+(studentGoalsPercentage || 0).toFixed(2)}% of Campus Jewish
                  Students
                </p>
              )}
            </div>
          </div>
        </div>
        <div>
          <p className="fw-700 mb-8">Engagement Goals</p>
          <p className="accent-text small-text line-height-double mb-8">
            Add goals of engagements you intend to have in each category.
            {isCurrentEngagementPeriod && (
              <span>
                {" "}
                You can tag specific students from the{" "}
                <span
                  className="link-text underline-text pointer"
                  onClick={() => Navigation.go("/engagement/students")}
                >
                  student list
                </span>
                .
              </span>
            )}
          </p>
          <Divider className="mt-12 mb-16" />
          <div>
            {interactionCategories &&
              interactionCategories.map(
                (interactionCategory, categoryIndex) => {
                  const isExpanded =
                    expandedCategoryIds.indexOf(interactionCategory.id) >= 0;
                  const categoryInteractionTypes = interactionTypes.filter(
                    (t) => t.categoryID === interactionCategory.id,
                  );
                  const categoryGoalsTotal = sum(
                    interactionGoals
                      .filter((g) =>
                        categoryInteractionTypes.find(
                          (t) => t.id === g.interactionTypeID,
                        ),
                      )
                      .map((g) => sum([g.goal])),
                  );
                  const categoryGoalsInteractionTypes = interactionGoals
                    .filter((cg) => cg.goal > 0)
                    .map((cg) => cg.interactionTypeID);
                  //'soft' goals refer to tagged engagement types which do not have a set goal
                  const categoryTargetedGoalsSoftTotal = targetedStudents
                    ? targetedStudents
                        .map((t) => t.interactionTypeIDs)
                        .flat()
                        .filter(
                          (i) =>
                            categoryInteractionTypes.find((t) => t.id === i) &&
                            !categoryGoalsInteractionTypes.includes(i),
                        ).length
                    : 0;

                  const categoryTargetedGoalsTotal = targetedStudents
                    ? targetedStudents
                        .map((t) => t.interactionTypeIDs)
                        .flat()
                        .filter((i) =>
                          categoryInteractionTypes.find((t) => t.id === i),
                        ).length
                    : 0;
                  return (
                    <div key={categoryIndex}>
                      <div
                        className="flex flex-align-center flex-justify-space mb-8"
                        onClick={() =>
                          toggleShowHideCategory(interactionCategory.id)
                        }
                        style={{
                          cursor: "pointer",
                          borderBottom: "1px solid lightgray",
                          paddingBottom: "6px",
                        }}
                      >
                        <div>
                          <p className="fw-700">{interactionCategory.name}</p>
                          <div className="interaction-category-rating mt-8 full-width">
                            {Array(interactionCategory.rating)
                              .fill()
                              .map((_, i) => (
                                <img
                                  alt=""
                                  className="mr-4"
                                  key={i}
                                  src="/images/flame-solid.png"
                                  style={{ height: "12px" }}
                                />
                              ))}
                          </div>
                        </div>
                        <div className="flex flex-align-center small-text">
                          <div>
                            <p className="no-wrap">
                              <span className="fw-700">
                                {formatNumber(
                                  categoryGoalsTotal +
                                    categoryTargetedGoalsSoftTotal,
                                )}
                              </span>{" "}
                              total
                            </p>
                            <p className="mb-4 mt-4 accent-text">
                              {categoryTargetedGoalsTotal
                                ? `${formatNumber(
                                    categoryTargetedGoalsTotal,
                                  )} tagged`
                                : ""}
                            </p>
                          </div>
                          <i className="material-icons accent-text large-text pointer ml-8">
                            {isExpanded ? "expand_less" : "expand_more"}
                          </i>
                        </div>
                      </div>
                      {isExpanded && (
                        <div>
                          {categoryInteractionTypes.map(
                            (interactionType, typeIndex) => {
                              const goalIndex = interactionGoals.findIndex(
                                (g) =>
                                  g.interactionTypeID === interactionType.id,
                              );
                              const interactionGoal =
                                interactionGoals[goalIndex] || {};
                              const targetedInteractionGoal = targetedStudents
                                ? targetedStudents.filter((t) =>
                                    t.interactionTypeIDs?.includes(
                                      interactionType.id,
                                    ),
                                  ).length
                                : 0;

                              return (
                                <div>
                                  <div
                                    className="flex flex-align-center flex-justify-space"
                                    key={typeIndex}
                                    style={{
                                      marginBottom: didPeriodEnd
                                        ? "12px"
                                        : "8px",
                                    }}
                                  >
                                    <div>
                                      <p className="fw-700">
                                        {interactionType.name}
                                      </p>
                                      <p className="small-text fw-300 mt-4 mb-4 accent-text">{`${
                                        interactionGoal.goal &&
                                        targetedInteractionGoal
                                          ? "Includes"
                                          : ""
                                      } ${targetedInteractionGoal} tagged`}</p>
                                    </div>
                                    <div className="flex flex-align-center">
                                      {didPeriodEnd ? (
                                        <p
                                          className={`${
                                            targetedInteractionGoal &&
                                            !interactionGoal.goal
                                              ? "accent-text fw-300"
                                              : ""
                                          }`}
                                        >
                                          {interactionGoal.goal
                                            ? formatNumber(interactionGoal.goal)
                                            : targetedInteractionGoal
                                            ? formatNumber(
                                                targetedInteractionGoal,
                                              )
                                            : ""}
                                        </p>
                                      ) : (
                                        <Tooltip
                                          placement="right"
                                          title={
                                            !!targetedInteractionGoal &&
                                            !!interactionGoal.goal &&
                                            interactionGoal.goal ===
                                              targetedInteractionGoal
                                              ? `You cannot set the goal any lower because you have ${targetedInteractionGoal} tagged ${pluralizeText(
                                                  "student",
                                                  targetedInteractionGoal,
                                                )}.`
                                              : ""
                                          }
                                          arrow
                                        >
                                          <span>
                                            <Tooltip
                                              title={
                                                targetedInteractionGoal &&
                                                !interactionGoal.goal
                                                  ? "Calculated based on tagged students."
                                                  : ""
                                              }
                                              placement="right"
                                              arrow
                                            >
                                              <input
                                                className="custom-input full-width"
                                                readOnly={didPeriodEnd}
                                                min={1}
                                                name="goal"
                                                onBlur={(e) => {
                                                  const minValue =
                                                    targetedInteractionGoal ||
                                                    1;
                                                  if (
                                                    e.target.value < minValue &&
                                                    e.target.value !== ""
                                                  ) {
                                                    setInteractionValidationObject(
                                                      {
                                                        ...interactionValidationObject,
                                                        [goalIndex]:
                                                          "You cannot set a goal lower than the number of tagged students",
                                                      },
                                                    );
                                                  }
                                                }}
                                                onChange={(e) =>
                                                  onChangeInteractionGoal(
                                                    e.target.name,
                                                    e.target.value,
                                                    goalIndex,
                                                    interactionType.id,
                                                    targetedInteractionGoal,
                                                  )
                                                }
                                                type="number"
                                                value={
                                                  interactionGoal.goal || ""
                                                }
                                                style={{ width: "80px" }}
                                                placeholder={
                                                  targetedInteractionGoal || ""
                                                }
                                              />
                                            </Tooltip>
                                          </span>
                                        </Tooltip>
                                      )}
                                    </div>
                                  </div>
                                  <div className="error-text small-text mb-4">
                                    {interactionValidationObject[goalIndex]}
                                  </div>
                                </div>
                              );
                            },
                          )}
                        </div>
                      )}
                    </div>
                  );
                },
              )}
          </div>
        </div>
        {!didPeriodEnd && (isMobileView() || isTabletView()) && (
          <div className="mt-16">
            <div className="flex flex-align-center flex-justify-end">
              <button
                className="btn btn-light btn-medium"
                disabled={submitLoading || !hasUnsavedChanges}
                style={{ padding: "0 32px", width: "50%" }}
                onClick={onCancelChanges}
              >
                Cancel
              </button>
              <button
                className="btn btn-accent btn-medium ml-16"
                disabled={
                  submitLoading ||
                  !hasUnsavedChanges ||
                  !!Object.keys(studentValidationObject).length ||
                  !!Object.keys(interactionValidationObject).length
                }
                style={{ padding: "0 32px", width: "50%" }}
                onClick={submitGoals}
              >
                {submitLoading ? "Saving..." : "Save"}
              </button>
            </div>
            {submitErrorMessage && (
              <p className="small-text error-text text-right mt-4">
                {submitErrorMessage}
              </p>
            )}
          </div>
        )}
      </div>
    )
  );
}
