import React from "react";
import { Prompt } from "react-router-dom";
import ImageUpload from "../../../../components/formik/ImageWithCropUpload";
import Radio from "../../../../components/form/Radio";
import _cloneDeep from "lodash.clonedeep";
import _isEqual from "lodash.isequal";
import _set from "lodash.set";
import { getFormattedValuesForForm, removeEmptyFromObj } from "../../../../lib";
import EmailRecipientsSection from "../../../../components/EmailRecipientsSection";
import { EduProgramTypes } from "../../eduUtils";

export default class EnrollmentSettings extends React.PureComponent {
  constructor(props) {
    super(props);

    const state = {
      ...this.getInitializedEnrollment(),
      errorMessage: "",
      submitAttempted: false,
    };

    state.initialState = _cloneDeep(state);

    this.state = state;
  }

  incompleteErrorMessage = "Please complete required fields";

  getInitializedEnrollment = () => {
    const {
      enrollment: { data: enrollment },
    } = this.props;

    const initializedEnrollment = getFormattedValuesForForm(enrollment);

    return {
      enrollment: initializedEnrollment,
      initialEnrollment: _cloneDeep(initializedEnrollment),
      overrideDefaultImageURL: !!enrollment.eduProgramImageURLOverride,
      overrideDefaultMessage: !!enrollment.eduProgramMessageOverride,
    };
  };

  onChangeEnrollment = (name, value, otherChanges) => {
    if (this.props.submitEnrollment.loading) {
      return;
    }

    let enrollment = _cloneDeep(this.state.enrollment);
    _set(enrollment, name, value);

    if (otherChanges) {
      Object.keys(otherChanges).forEach((change) =>
        _set(enrollment, change, otherChanges[change]),
      );
    }

    return new Promise((resolve, reject) => {
      this.setState({ enrollment }, () => {
        resolve();

        //if required fields message is shown, re-validate on change
        const { errorMessage } = this.state;
        if (errorMessage && errorMessage === this.incompleteErrorMessage) {
          const isValid = this.validateEnrollment();
          if (isValid) {
            this.setState({
              errorMessage: "",
            });
          }
        }
      });
    });
  };

  onChangeEnrollmentEvt = ({ target: { name, value } }) => {
    return this.onChangeEnrollment(name, value);
  };

  onCancelEnrollmentChanges = () => {
    this.setState(this.state.initialState);
  };

  onSubmitEnrollment = async () => {
    this.setState({ errorMessage: "", submitAttempted: true });

    const isValid = this.validateEnrollment();
    if (!isValid) {
      this.setState({
        errorMessage: this.incompleteErrorMessage,
      });
      return;
    }

    const { newEnrollment } = this.props;

    const enrollmentForSubmission = { ...this.state.enrollment };
    if (newEnrollment) {
      //activate new enrollment
      enrollmentForSubmission.isPending = false;
    }
    removeEmptyFromObj(enrollmentForSubmission);
    await this.props.actions.submitEduEnrollment(enrollmentForSubmission);

    const {
      submitEnrollment: { error, errorMessage },
      toggleShowEnrollmentCompleted,
    } = this.props;

    if (error) {
      this.setState({
        errorMessage:
          errorMessage || "Something went wrong.  Please try again.",
      });
    } else {
      if (newEnrollment && toggleShowEnrollmentCompleted) {
        toggleShowEnrollmentCompleted();
      } else {
        const updatedState = {
          ...this.state.initialState,
          ...this.getInitializedEnrollment(),
        };
        this.setState(updatedState);
      }
    }
  };

  toggleOverrideDefaultSetting = (name, value, overrideFieldName) => {
    this.setState(
      {
        [name]: value,
      },
      () => {
        if (!value && this.state.enrollment[overrideFieldName]) {
          this.onChangeEnrollment(overrideFieldName, "");
        }
      },
    );
  };

  validateEnrollment = () => {
    const {
      enrollment: {
        eduProgramImageURLOverride,
        eduProgramMessageOverride,
        emailRecipients,
      },
      overrideDefaultImageURL,
      overrideDefaultMessage,
    } = this.state;

    if (
      emailRecipients.length === 0 ||
      (overrideDefaultMessage && !eduProgramMessageOverride) ||
      (overrideDefaultImageURL && !eduProgramImageURLOverride)
    ) {
      return false;
    }

    return true;
  };

  render() {
    const {
      newEnrollment,
      submitEnrollment: { loading },
    } = this.props;

    const {
      enrollment,
      errorMessage,
      initialEnrollment,
      initialState,
      overrideDefaultImageURL,
      overrideDefaultMessage,
      submitAttempted,
    } = this.state;

    const {
      chabadHouseID,
      defaultImageURL,
      defaultMessage,
      eduProgramImageURLOverride,
      eduProgramMessageOverride,
      eduProgramName,
      eduProgramType,
      emailRecipients,
      websiteURL,
    } = enrollment;

    const hasChanges =
      !newEnrollment &&
      (!_isEqual(initialEnrollment, enrollment) ||
        overrideDefaultImageURL !== initialState.overrideDefaultImageURL ||
        overrideDefaultMessage !== initialState.overrideDefaultMessage);

    return (
      <div>
        <div className="edu-card card enrollment-card">
          <Prompt
            when={hasChanges}
            message="Your changes have not been saved. Are you sure you want to leave this page?"
          />
          {newEnrollment ? (
            <h2 className="page-title line-height-double">
              Welcome to {eduProgramName}!
              <span className="accent-text-dark medium-text fw-400 block">
                First, let's set up your website
              </span>
            </h2>
          ) : (
            <h2 className="page-title">
              {eduProgramType === EduProgramTypes.JewishU
                ? "Webpage Settings"
                : "Settings"}
            </h2>
          )}
          {eduProgramType === EduProgramTypes.JewishU && (
            <>
              <div className="edu-form-section">
                <p className="medium-text fw-700 mb-8">Main Message</p>
                <p className="accent-text-dark mb-24 line-height-double">
                  The main message will be displayed in large on the top section
                  of your Chabad House’s {eduProgramName} web page.
                </p>
                <div>
                  <Radio
                    name="overrideDefaultMessage"
                    onChange={(name, value) =>
                      this.toggleOverrideDefaultSetting(
                        name,
                        value,
                        "eduProgramMessageOverride",
                      )
                    }
                    options={[
                      {
                        value: false,
                        display: "Use the default main message",
                        content: (
                          <p className="small-text mb-24 ml-40 accent-text line-height-double pre-wrap">
                            {defaultMessage}
                          </p>
                        ),
                      },
                      {
                        value: true,
                        display: "Create custom main message",
                        content: (
                          <React.Fragment>
                            <textarea
                              className="custom-input custom-message mt-8"
                              disabled={!overrideDefaultMessage}
                              value={eduProgramMessageOverride}
                              placeholder="Type custom main message"
                              name="eduProgramMessageOverride"
                              onChange={this.onChangeEnrollmentEvt}
                            />
                            {submitAttempted &&
                              overrideDefaultMessage &&
                              !eduProgramMessageOverride && (
                                <p className="error-message">
                                  please enter your custom main message
                                </p>
                              )}
                          </React.Fragment>
                        ),
                      },
                    ]}
                    value={overrideDefaultMessage}
                  />
                </div>
              </div>
              <div className="edu-form-section">
                <p className="medium-text fw-700 mb-8">Main Image </p>
                <p className="accent-text-dark mb-24 line-height-double">
                  The main image will be displayed in large at the top section
                  of your Chabad House’s {eduProgramName} web page.
                </p>
                <div>
                  <Radio
                    name="overrideDefaultImageURL"
                    onChange={(name, value) =>
                      this.toggleOverrideDefaultSetting(
                        name,
                        value,
                        "eduProgramImageURLOverride",
                      )
                    }
                    options={[
                      {
                        value: false,
                        display: "Use the default main image",
                        content: (
                          <div className="image-upload-container ml-40 mt-8 mb-24">
                            <img src={defaultImageURL} alt="" />
                          </div>
                        ),
                      },
                      {
                        value: true,
                        display: "Choose custom main image",
                        content: (
                          <React.Fragment>
                            <div className="ml-40 mt-8 mb-24">
                              <ImageUpload
                                className={
                                  !overrideDefaultImageURL ? "disabled" : ""
                                }
                                cropAspectWidth={3}
                                cropAspectHeight={1}
                                disabled={!overrideDefaultImageURL}
                                imageType="banner"
                                imageURL={
                                  eduProgramImageURLOverride
                                    ? eduProgramImageURLOverride
                                    : null
                                }
                                onChange={this.onChangeEnrollment}
                                label="Upload Image"
                                name="eduProgramImageURLOverride"
                                uploadedLabel="Change Image"
                              />
                            </div>
                            {submitAttempted &&
                              overrideDefaultImageURL &&
                              !eduProgramImageURLOverride && (
                                <p className="error-message">
                                  please choose a custom main image
                                </p>
                              )}
                          </React.Fragment>
                        ),
                      },
                    ]}
                    value={overrideDefaultImageURL}
                  />
                </div>
              </div>
            </>
          )}
          <EmailRecipientsSection
            chabadHouseID={chabadHouseID}
            emailRecipients={emailRecipients || []}
            formSectionClassName="edu-form-section"
            onChange={(recipientsChange) =>
              this.onChangeEnrollment("emailRecipients", recipientsChange)
            }
            submitAttempted={submitAttempted}
          />
          {!newEnrollment && (
            <div className="edu-form-section">
              <p className="medium-text fw-700 mb-8">Your Webpage Url</p>
              <div className="flex flex-align-center tablet-block mobile-block">
                <p className="accent-text-dark mr-24 tablet-mt-8 tablet-mb-8 mobile-mt-8 mobile-mb-8">
                  Your Chabad House's Url is
                </p>
                <div className="raffles-link flex flex-align-center flex-justify-space">
                  <p>{websiteURL}</p>
                  <a
                    className="link-text small-text flex flex-align-center ml-16 no-wrap"
                    href={websiteURL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="material-icons medium-text mr-8">
                      open_in_new
                    </i>
                    Go to Link
                  </a>
                </div>
              </div>
              {!!chabadHouseID && (
                <p className="small-text accent-text-dark mt-8">
                  The URL Friendly Name (the part of the URL that references
                  your Chabad House) can be updated in your Chabad House profile
                  by clicking{" "}
                  <a
                    className="link-text"
                    href={`/account`} //TODO scroll to chabad house section
                  >
                    here
                  </a>
                </p>
              )}
            </div>
          )}
        </div>
        <div className="flex flex-justify-end relative edu-form-btns">
          {newEnrollment ? (
            <button
              className="btn btn-accent btn-large mt-40"
              style={{ padding: "0 32px" }}
              onClick={this.onSubmitEnrollment}
            >
              {loading ? "Activating..." : "Activate"}
            </button>
          ) : (
            <React.Fragment>
              <button
                className="btn btn-light btn-large mt-40"
                disabled={loading || !hasChanges}
                style={{ padding: "0 32px" }}
                onClick={this.onCancelEnrollmentChanges}
              >
                Cancel
              </button>
              <button
                className="btn btn-accent btn-large mt-40 ml-16"
                disabled={loading || !hasChanges}
                style={{ padding: "0 32px" }}
                onClick={this.onSubmitEnrollment}
              >
                {loading ? "Saving..." : "Save"}
              </button>
            </React.Fragment>
          )}
          {submitAttempted && !loading && errorMessage && (
            <p className="error-message">{errorMessage}</p>
          )}
        </div>
      </div>
    );
  }
}
