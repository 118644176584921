import React, { useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { CustomBarLabel, CustomGoalBarLabel } from "./";

export default function MetricsBarChart(props) {
  const {
    chartHeight,
    data,
    getColors,
    isGoalData,
    metrics,
    tickYFormatter,
    tickYDataKey,
    tickXFormatter,
    groupByMonth = false,
    xDomain = [0, 100],
    tickComponent,
  } = props;

  const [bandOffset, setBandOffset] = useState(0);
  const [bandSize, setBandSize] = useState(0);

  const setChartRef = (node) => {
    const { bandSize = 0, y: bandOffset = 0 } = node?.state.yAxisMap[0] || {};
    setBandOffset(bandOffset);
    setBandSize(bandSize);
  };

  return (
    <ResponsiveContainer height={chartHeight} width="94%">
      <BarChart barGap={1} data={data} layout="vertical" ref={setChartRef}>
        {metrics?.map((metric, index) => {
          const value = groupByMonth ? metric.enumValue : metric;
          const colors = getColors(value);
          const id =
            !isGoalData && !groupByMonth
              ? new Date(value).toISOString()
              : value;

          return (
            <Bar
              barSize={12}
              dataKey={id.toString()}
              fill={colors.light}
              key={`${index}-${id}`}
              label={
                isGoalData ? (
                  <CustomGoalBarLabel colors={colors} data={data} id={id} />
                ) : (
                  <CustomBarLabel colors={colors} data={data} id={id} />
                )
              }
              radius={[0, 10, 10, 0]}
            >
              {data.map((_, index) => (
                <Cell key={index} fill={colors.light} />
              ))}
            </Bar>
          );
        })}

        <YAxis
          tick={tickComponent}
          dataKey={tickYDataKey}
          tickFormatter={tickYFormatter}
          tickLine={false}
          tickMargin={10}
          type="category"
        />

        <XAxis
          domain={xDomain}
          tickFormatter={tickXFormatter}
          tickLine={false}
          type="number"
        />

        {bandSize && (
          <CartesianGrid
            vertical={false}
            horizontalPoints={Array(data.length)
              .fill()
              .map((_, i) => i * bandSize + bandOffset)}
          />
        )}
      </BarChart>
    </ResponsiveContainer>
  );
}
