import { AuthRequest } from "../../lib";
import queryString from "query-string";
import { AccountActions } from "../account/actions";
import { SysActions } from "../sys/actions";

export const TripActions = {
  SET_TRIP_EVENT_LOADING: " SET_TRIP_EVENT_LOADING",
  SET_TRIP_ENROLLMENT_LOADING: " SET_TRIP_ENROLLMENT_LOADING",
  SET_TRIP_SCHEDULE_LOADING: " SET_TRIP_SCHEDULE_LOADING",
  SET_TRIP_REGISTRATIONS_LOADING: "SET_TRIP_REGISTRATIONS_LOADING",
  SET_TRIP_REGISTRATION_LOADING: "SET_TRIP_REGISTRATION_LOADING",
  SET_TRIP_METRICS_LOADING: "SET_TRIP_METRICS_LOADING",
  UPDATE_TRIP: "UPDATE_TRIP",
  SET_TRIP_SETTINGS_LOADING: "SET_TRIP_SETTINGS_LOADING",
  SET_TRIP_ENROLLMENT_FORM_SETTINGS: "SET_TRIIP_ENROLLMENT_FORM_SETTINGS",

  getShliachTrip(id, scheduleID) {
    return async (dispatch) => {
      let success = true;
      let newState = {};

      const { data } = await AuthRequest.get(
        `TripEnrollments/shliach/${id}?programScheduleId=${scheduleID}`,
      ).catch((err) => {
        success = false;
        newState = {
          success,
        };
        return err;
      });

      if (success) {
        newState = {
          shliach: data.payload,
          success,
        };
      }

      dispatch(TripActions.updateTrip(newState));
    };
  },
  getTripEvent(id) {
    return async (dispatch) => {
      dispatch(TripActions.setTripEventLoading(true));
      let success = true;
      let newState = {};

      const { data } = await AuthRequest.get(`TripEvents/${id}`).catch(
        (err) => {
          success = false;
          newState = {
            event: {
              loading: false,
            },
            error: err,
            errorMessage:
              err && err.response && err.response.status === 404
                ? "Event404"
                : "",
            success,
          };
          return err;
        },
      );

      if (success) {
        newState = {
          event: {
            ...data.payload,
            loading: false,
          },
          success,
        };
      }

      dispatch(TripActions.updateTrip(newState));
    };
  },
  getTripEnrollment(id, eventID) {
    return async (dispatch) => {
      dispatch(TripActions.setTripEnrollmentLoading(true));
      let success = true;
      let newState = {};

      const { data } = await AuthRequest.get(
        `TripEnrollments/${id}?tripEventID=${eventID}${
          id === 0 ? `&returnBlank=true` : ""
        }`,
      ).catch((err) => {
        success = false;
        newState = {
          enrollment: {
            loading: false,
            errorStatus: err.response.status,
          },
          error: err,
          success,
        };
        return err;
      });
      if (success) {
        newState = {
          enrollment: {
            ...data.payload,
            loading: false,
          },
          success,
        };
      }

      dispatch(TripActions.updateTrip(newState));
    };
  },
  submitTripEnrollment(
    isTravelTrip,
    updatedEnrollment,
    stripeValues,
    createStripeToken,
    id,
    refreshBanners,
  ) {
    return async (dispatch) => {
      let success = true;
      let message = "";
      let newState = {};
      if (
        !isTravelTrip &&
        !updatedEnrollment.billing.useCardOnFile &&
        createStripeToken
      ) {
        let { token } = await createStripeToken(stripeValues);
        if (token) {
          updatedEnrollment.billing.stripeToken = token;
        } else {
          success = false;
        }
      }

      if (success) {
        const response = await AuthRequest.post(`TripEnrollments`, {
          ...updatedEnrollment,
          isTravelTrip,
        }).catch((err) => {
          success = false;
          if (err.response && err.response.data && err.response.data.messages) {
            message =
              err.response.status === 500
                ? "Error processing your request"
                : err.response.data.messages.join(" \n");
          }
          newState = {
            enrollment: { ...updatedEnrollment, success },
            errorMessage: message,
            success,
          };
        });

        if (success) {
          newState = {
            enrollment: {
              ...response.data.payload,
              success,
            },
            errorMessage: "",
            success,
          };
          if (id) {
            dispatch(AccountActions.getAccountInfo(id, false));
          }
        }
        dispatch(TripActions.updateTrip(newState));

        if (refreshBanners) {
          dispatch(SysActions.getSystemData());
        }
      }
      return newState;
    };
  },
  getTripSchedule(id, scheduleID) {
    return async (dispatch) => {
      dispatch(TripActions.setTripScheduleLoading(true));
      let success = true;

      const { data } = await AuthRequest.get(
        `Programs/${id}/schedules/${scheduleID}`,
      ).catch((err) => {
        success = false;
        return err;
      });

      const newState = {
        schedule: {
          ...data.payload,
          loading: false,
          success,
        },
      };

      dispatch(TripActions.updateTrip(newState));
    };
  },
  getTripRegistrations(
    page,
    results,
    searchTerm,
    enrollmentID,
    eventID,
    status,
    tourIDs = [],
  ) {
    return async (dispatch) => {
      dispatch(TripActions.setTripRegistrationsLoading(true));
      let success = true;
      let newState = {};
      const params = {
        page: page,
        results: results,
        statuses: status || undefined,
        tripEnrollmentID: enrollmentID,
        text: searchTerm || "",
        tripEventID: eventID,
        tourIDs: tourIDs,
      };

      const { data } = await AuthRequest.get(
        `TripRegistrations/byEvent?${queryString.stringify(params)}`,
      ).catch((err) => {
        success = false;
        newState = {
          registrations: {
            loading: false,
            success,
          },
        };
        return err;
      });

      if (success) {
        newState = {
          registrations: {
            ...data.payload,
            loading: false,
            success,
          },
        };
      }

      dispatch(TripActions.updateTrip(newState));
    };
  },
  getTripRegistrationsForExport(enrollmentID, eventID, searchTerm, status) {
    return async (dispatch) => {
      let success = true;
      const params = {
        exportType: "Basic",
        statuses: status || undefined,
        text: searchTerm,
        tripEnrollmentID: enrollmentID,
        tripEventID: eventID,
      };
      const { data } = await AuthRequest.get(
        `TripRegistrations/byEvent?${queryString.stringify(params)}`,
      ).catch((err) => {
        success = false;
        return err;
      });
      const newState = {
        registrationsForExport: {
          ...data.payload,
        },
        success,
      };
      dispatch(TripActions.updateTrip(newState));
    };
  },
  getTripRegistrationDetails(id) {
    return async (dispatch) => {
      dispatch(TripActions.setTripRegistrationLoading(true));
      let success = true;
      let newState = {};

      const { data } = await AuthRequest.get(`TripRegistrations/${id}`).catch(
        (err) => {
          success = false;
          newState = {
            registration: {
              loading: false,
              success,
            },
          };
          return err;
        },
      );

      if (success) {
        newState = {
          registration: {
            ...data.payload,
            loading: false,
            success,
          },
        };
      }

      dispatch(TripActions.updateTrip(newState));
    };
  },
  updateTripRegistration(updatedRegistration) {
    return async (dispatch) => {
      let success = true;
      let newState;
      const updatedRegistrationData = await AuthRequest.post(
        `TripRegistrations/status`,
        updatedRegistration,
      ).catch((err) => {
        success = false;
        newState = {
          updatedRegistration: {
            loading: false,
            errorMessage: err,
            success,
          },
        };
      });
      if (success) {
        newState = {
          updatedRegistration: {
            ...updatedRegistrationData.data.payload,
            success,
          },
        };
      }
      dispatch(TripActions.updateTrip(newState));
    };
  },
  submitTripRegistrationRecommendation(registrationId, recommendation) {
    return async () => {
      let success = true;

      await AuthRequest.post(
        `TripRegistrations/${registrationId}/recommendation`,
        recommendation,
      ).catch((err) => {
        success = false;
      });

      return success;
    };
  },
  getTripMetrics(id) {
    return async (dispatch) => {
      dispatch(TripActions.setTripMetricsLoading(true));
      let success = true;

      const { data } = await AuthRequest.get(
        `TripEnrollments/${id}/metrics`,
      ).catch((err) => {
        success = false;
        const newState = {
          metrics: {
            loading: false,
            errorMessage: err,
            success,
          },
        };
        dispatch(TripActions.updateTrip(newState));
        return err;
      });

      if (success) {
        const newState = {
          metrics: {
            ...data.payload,
            loading: false,
            success,
          },
        };
        dispatch(TripActions.updateTrip(newState));
      }
    };
  },
  getHistoricTripEnrollments(id) {
    return async (dispatch) => {
      let success = true;
      const { data } = await AuthRequest.get(
        `TripEnrollments/shliach/${id}`,
      ).catch((err) => {
        success = false;
        return err;
      });
      if (success) {
        const newState = {
          historicEnrollments: data.payload,
          success,
        };

        dispatch(TripActions.updateTrip(newState));
      }
    };
  },
  getHistoricTrip(id, scheduleID) {
    return async (dispatch) => {
      // dispatch(TripActions.setTripEnrollmentLoading(true));
      // dispatch(TripActions.setTripEventLoading(true));
      let success = true;
      const { data } = await AuthRequest.get(
        `TripEnrollments/${id}?returnBlank=true`,
      ).catch((err) => {
        success = false;
        return err;
      });
      const { data: event } = await AuthRequest.get(
        `TripEvents/${scheduleID}`,
      ).catch((err) => {
        success = false;
        return err;
      });
      if (success) {
        const newState = {
          event: {
            ...event.payload,
            loading: false,
          },
          enrollment: {
            ...data.payload,
            loading: false,
            success,
          },
          success,
        };

        dispatch(TripActions.updateTrip(newState));
      }
    };
  },
  getTripGeneralSettings(id) {
    return async (dispatch) => {
      dispatch(TripActions.setTripSettingsLoading(true));
      let success = true;
      let data = {};
      let errors = {};

      await Promise.all([
        AuthRequest.get(`Shluchim/${id}/primaryChabadHouse/staff`)
          .then((res) => (data.staff = res.data))
          .catch((e) => (errors.staff = true)),
        AuthRequest.get(`Campuses/basic?shliachID=${id}`)
          .then((res) => (data.schools = res.data))
          .catch((e) => (errors.schools = true)),
      ]);

      const newState = {
        ...data,
        loading: false,
        success,
      };

      dispatch(TripActions.setTripEnrollmentFormSettings(newState));
    };
  },
  setTripEnrollmentLoading(payload) {
    return {
      type: TripActions.SET_TRIP_ENROLLMENT_LOADING,
      payload,
    };
  },
  setTripEventLoading(payload) {
    return {
      type: TripActions.SET_TRIP_EVENT_LOADING,
      payload,
    };
  },
  setTripScheduleLoading(payload) {
    return {
      type: TripActions.SET_TRIP_SCHEDULE_LOADING,
      payload,
    };
  },
  setTripRegistrationsLoading(payload) {
    return {
      type: TripActions.SET_TRIP_REGISTRATIONS_LOADING,
      payload,
    };
  },
  setTripRegistrationLoading(payload) {
    return {
      type: TripActions.SET_TRIP_REGISTRATION_LOADING,
      payload,
    };
  },
  setTripMetricsLoading(payload) {
    return {
      type: TripActions.SET_TRIP_METRICS_LOADING,
      payload,
    };
  },
  updateTrip(payload) {
    return {
      type: TripActions.UPDATE_TRIP,
      payload,
    };
  },
  setTripSettingsLoading(payload) {
    return {
      type: TripActions.SET_TRIP_SETTINGS_LOADING,
      payload,
    };
  },
  setTripEnrollmentFormSettings(payload) {
    return {
      type: TripActions.SET_TRIP_ENROLLMENT_FORM_SETTINGS,
      payload,
    };
  },
};
