import React from "react";
import { Prompt } from "react-router-dom";
import CopyToClipboardTextbox from "../../../../components/CopyToClipboardTextbox";
import Toggle from "../../../../components/form/Toggle";
import Radio from "../../../../components/form/Radio";
import StudentProfileSettingsModal from "./StudentProfileSettingsModal";
import EmailRecipientsSection from "../../../../components/EmailRecipientsSection";
import _cloneDeep from "lodash.clonedeep";
import _isEqual from "lodash.isequal";
import _set from "lodash.set";
import {
  getFormattedValuesForForm,
  removeEmptyFromObj,
  AuthTypes,
} from "../../../../lib";
import RsvpCustomQuestion from "./RsvpCustomQuestion";
import { Button, IconButton, Typography } from "@material-ui/core";
import { AddCircle } from "@material-ui/icons";
import DefaultReminders from "./DefaultReminders";

export default class EnrollmentSettings extends React.PureComponent {
  constructor(props) {
    super(props);

    const state = {
      ...this.getInitializedEnrollment(),
      errorMessage: "",
      showStudentProfileSettingsModal: false,
      submitAttempted: false,
      studentRemindersError: null,
    };

    state.initialState = _cloneDeep(state);

    this.state = state;

    this.isShliach = props.auth.loggedInAs === AuthTypes.shliach;
  }

  incompleteErrorMessage = "Please complete required fields";

  componentDidMount() {
    this.getStudentProfileSettings();
  }

  getStudentProfileSettings = () => {
    const {
      actions: { getStudentProfileSettings },
      enrollment: {
        data: { forChabadHouseID, programID },
      },
    } = this.props;

    getStudentProfileSettings(forChabadHouseID, programID);
  };

  getInitializedEnrollment = () => {
    const {
      enrollment: { data: enrollment },
      newEnrollment,
    } = this.props;

    const initializedEnrollment = getFormattedValuesForForm(enrollment);

    return {
      enrollment: initializedEnrollment,
      initialEnrollment: _cloneDeep(initializedEnrollment),
      shouldOverridePolicy: newEnrollment ? false : !!enrollment.overridePolicy,
      shouldOverrideSmsWelcomeMessage: newEnrollment
        ? false
        : !!enrollment.overrideSmsWelcomeMessage,
    };
  };

  onChangeEnrollment = (name, value, otherChanges) => {
    if (this.props.submitEnrollment.loading) {
      return;
    }

    let enrollment = _cloneDeep(this.state.enrollment);
    _set(enrollment, name, value);

    if (otherChanges) {
      Object.keys(otherChanges).forEach((change) =>
        _set(enrollment, change, otherChanges[change]),
      );
    }
    return new Promise((resolve, reject) => {
      this.setState({ enrollment }, () => {
        resolve();

        //if required fields message is shown, re-validate on change
        const { errorMessage } = this.state;
        if (errorMessage && errorMessage === this.incompleteErrorMessage) {
          const isValid = this.validateEnrollment();
          if (isValid) {
            this.setState({
              errorMessage: "",
            });
          }
        }
      });
    });
  };

  onChangeEnrollmentEvt = ({ target: { name, value } }) => {
    return this.onChangeEnrollment(name, value);
  };

  onAddRemoveRegistrationQuestion = (questionIndex) => {
    let registrationQuestions = _cloneDeep(
      this.state.enrollment.registrationQuestions,
    );

    if (questionIndex >= 0) {
      //delete question
      if (registrationQuestions[questionIndex].id) {
        //deactivate question if already saved to the db
        registrationQuestions[questionIndex].isActive = false;
      } else {
        //otherwise remove the question
        registrationQuestions = registrationQuestions.filter(
          (_, qIndex) => qIndex !== questionIndex,
        );
      }
    } else {
      //add question
      registrationQuestions = [
        ...registrationQuestions,
        {
          isActive: true,
          isEventSpecific: false,
          isResourceSpecific: false,
          isMandatory: false,
          questionText: "",
          responseType: "",
        },
      ];
    }

    this.onChangeEnrollment("registrationQuestions", registrationQuestions);
  };

  onCancelEnrollmentChanges = () => {
    this.setState(this.state.initialState);
  };

  onSubmitEnrollment = async () => {
    this.setState({ errorMessage: "", submitAttempted: true });

    const isValid = this.validateEnrollment();
    if (!isValid) {
      this.setState({
        errorMessage: this.incompleteErrorMessage,
      });
      return;
    }

    const { newEnrollment, toggleShowEnrollmentCompleted } = this.props;

    const enrollmentForSubmission = { ...this.state.enrollment };
    if (newEnrollment) {
      //activate new enrollment
      enrollmentForSubmission.isPending = false;

      //set showEnrollmentCompleted to true before submit action to ensure that myRsvp will mount immediately upon activation (when enrollment.id is no longer null)
      toggleShowEnrollmentCompleted();
    }
    removeEmptyFromObj(enrollmentForSubmission);
    await this.props.actions.submitRsvpEnrollment(enrollmentForSubmission);

    const {
      submitEnrollment: { error, errorMessage },
    } = this.props;

    if (error) {
      this.setState({
        errorMessage:
          errorMessage || "Something went wrong.  Please try again.",
      });

      //for new enrollment toggle back showEnrollmentCompleted on failure
      if (newEnrollment) {
        toggleShowEnrollmentCompleted();
      }
    } else if (!newEnrollment) {
      //update state upon enrollment update; for new enrollment myRSVP will show immediately, because we toggled showEnrollmentCompleted before submitted
      const updatedState = {
        ...this.state.initialState,
        ...this.getInitializedEnrollment(),
      };
      updatedState.initialState = _cloneDeep(updatedState);
      this.setState(updatedState);
    }
  };

  toggleOverrideDefaultSetting = (name, value, overrideFieldName) => {
    this.setState(
      {
        [name]: value,
      },
      () => {
        if (!value && this.state.enrollment[overrideFieldName]) {
          this.onChangeEnrollment(overrideFieldName, "");
        }
      },
    );
  };

  setPolicy(val) {
    let newState = {};
    let enrollment = { ...this.state.enrollment };
    switch (val) {
      case true:
        newState.shouldOverridePolicy = false;
        enrollment.overridePolicy = "";
        enrollment.isPolicyRequired = true;
        break;
      case false:
        enrollment.isPolicyRequired = false;
        enrollment.overridePolicy = "";
        newState.shouldOverridePolicy = false;
        break;
      case "custom":
        newState.shouldOverridePolicy = true;
        enrollment.isPolicyRequired = true;
        break;

      default:
        break;
    }
    newState.enrollment = enrollment;
    this.setState(newState);
  }

  validateEnrollment = () => {
    const {
      enrollment: {
        emailRecipients,
        overridePolicy,
        overrideSmsWelcomeMessage,
        registrationQuestions,
        globalDefaultHasReminders,
        globalDefaultRegisteredStudentsReminderMinutes,
        globalDefaultUnregisteredStudentsReminderMinutes,
      },
      shouldOverridePolicy,
      shouldOverrideSmsWelcomeMessage,
    } = this.state;
    if (
      emailRecipients.length === 0 ||
      (shouldOverridePolicy && !overridePolicy) ||
      (shouldOverrideSmsWelcomeMessage && !overrideSmsWelcomeMessage) ||
      (registrationQuestions &&
        registrationQuestions.some(
          (q) => q.isActive && (!q.questionText || !q.responseType),
        ))
    ) {
      return false;
    }
    if (
      globalDefaultHasReminders &&
      !globalDefaultRegisteredStudentsReminderMinutes &&
      !globalDefaultUnregisteredStudentsReminderMinutes
    ) {
      this.setState({ studentRemindersError: true });
      return false;
    }
    return true;
  };

  render() {
    const {
      actions,
      newEnrollment,
      studentProfileSettings: {
        error: studentProfileSettingsError,
        data: studentProfileSettings,
        loading: studentProfileSettingsLoading,
      },
      submitEnrollment: { loading },
      submitStudentProfileSettings: {
        error: submitStudentProfileSettingsError,
        loading: submitStudentProfileSettingsLoading,
      },
    } = this.props;

    const {
      enrollment,
      errorMessage,
      initialEnrollment,
      initialState,
      shouldOverridePolicy,
      shouldOverrideSmsWelcomeMessage,
      showStudentProfileSettingsModal,
      submitAttempted,
    } = this.state;

    const {
      chabadHouseRsvpURL,
      chabadHouseMessagingNumber,
      defaultPolicy,
      defaultSmsWelcomeMessage,
      emailRecipients,
      forChabadHouseID,
      isChabadHouseEnrolledInMessaging,
      isPolicyRequired,
      overridePolicy,
      overrideSmsWelcomeMessage,
      programID,
      registrationQuestions,
      isAllergiesRequiredForEvents,
      isAllergiesRequiredForResources,
      globalDefaultHasReminders,
      globalDefaultRegisteredStudentsReminderMinutes,
      globalDefaultUnregisteredStudentsReminderMinutes,
    } = enrollment;

    const activeRegistrationQuestions = (registrationQuestions || [])
      .map((q, index) => ({ ...q, index }))
      .filter((q) => q.isActive); // filter out inactive questions to hide from UI

    const hasChanges =
      !newEnrollment &&
      (!_isEqual(initialEnrollment, enrollment) ||
        shouldOverrideSmsWelcomeMessage !==
          initialState.shouldOverrideSmsWelcomeMessage);

    return (
      <div>
        {newEnrollment && (
          <h2 className="page-title line-height-double program-form-section-title rsvp-enrollment-settings-title">
            Welcome to the RSVP System!
          </h2>
        )}
        <div className="rsvp-enrollment-settings-card">
          <Prompt
            when={hasChanges}
            message="Your changes have not been saved. Are you sure you want to leave this page?"
          />
          {newEnrollment ? (
            <p className="xl-text fw-500 block program-form-section-title">
              Set up the basics
            </p>
          ) : (
            <h2 className="page-title program-form-section-title">
              RSVP Settings
            </h2>
          )}
          <div className="program-form-section">
            <p
              className="medium-text fw-300 mb-16"
              style={{ color: "#747579" }}
            >
              SMS Settings
            </p>
            <p className="medium-text fw-700 mb-8">SMS Welcome Message</p>
            <p className="accent-text-dark mb-24 line-height-double">
              The welcome message will be sent to each user when they sign up
              for SMS
            </p>
            <div>
              <Radio
                disabled={!this.isShliach}
                name="shouldOverrideSmsWelcomeMessage"
                onChange={(name, value) =>
                  this.toggleOverrideDefaultSetting(
                    name,
                    value,
                    "overrideSmsWelcomeMessage",
                  )
                }
                options={[
                  {
                    value: false,
                    display: "Use the default welcome message",
                    content: (
                      <p className="small-text mb-24 ml-40 accent-text line-height-double pre-wrap">
                        {defaultSmsWelcomeMessage}
                      </p>
                    ),
                  },
                  {
                    value: true,
                    display: "Create custom welcome message",
                    content: (
                      <React.Fragment>
                        <textarea
                          className="custom-input custom-message mt-8"
                          disabled={!shouldOverrideSmsWelcomeMessage}
                          value={overrideSmsWelcomeMessage}
                          placeholder="Type custom main message"
                          name="overrideSmsWelcomeMessage"
                          onChange={this.onChangeEnrollmentEvt}
                        />
                        {submitAttempted &&
                          shouldOverrideSmsWelcomeMessage &&
                          !overrideSmsWelcomeMessage && (
                            <p
                              className="error-message"
                              style={{ paddingLeft: 40 }}
                            >
                              please enter your custom welcome message
                            </p>
                          )}
                      </React.Fragment>
                    ),
                  },
                ]}
                value={shouldOverrideSmsWelcomeMessage}
              />
            </div>
          </div>
          <EmailRecipientsSection
            chabadHouseID={forChabadHouseID}
            disabled={!this.isShliach}
            emailRecipients={emailRecipients}
            formSectionClassName="program-form-section"
            onChange={(recipientsChange) =>
              this.onChangeEnrollment("emailRecipients", recipientsChange)
            }
            submitAttempted={submitAttempted}
          />
          <DefaultReminders
            globalDefaultHasReminders={globalDefaultHasReminders}
            onChangeEnrollment={this.onChangeEnrollment}
            globalDefaultRegisteredStudentsReminderMinutes={
              globalDefaultRegisteredStudentsReminderMinutes
            }
            globalDefaultUnregisteredStudentsReminderMinutes={
              globalDefaultUnregisteredStudentsReminderMinutes
            }
            error={this.state.studentRemindersError}
            onSetHasReminders={(value) => {
              if (value) {
                this.setState({
                  enrollment: {
                    ...enrollment,
                    globalDefaultRegisteredStudentsReminderMinutes: "",
                    globalDefaultUnregisteredStudentsReminderMinutes: "",
                    globalDefaultHasReminders: value,
                  },
                });
              } else {
                this.onChangeEnrollment("globalDefaultHasReminders", value);
              }
            }}
          />
          <div className="program-form-section">
            <Typography variant="h6" className="mb-8">
              Covid-19 Policy Terms and Conditions for Students
            </Typography>

            <Typography
              variant="body2"
              style={{ color: "#747579" }}
              className="mb-24"
            >
              Please set your Chabad House’s Covid-19 Policy that students will
              need to accept before applying.
            </Typography>

            <div>
              <Radio
                name="policyRequiredSettings"
                onChange={(name, value) => this.setPolicy(value)}
                options={[
                  {
                    value: true,
                    display: "Use the default Covid-19 Policy",
                    content: (
                      <Typography
                        variant="body2"
                        style={{ color: "#747579" }}
                        className="mb-24 ml-40 pre-wrap"
                      >
                        {defaultPolicy}
                      </Typography>
                    ),
                  },
                  {
                    value: false,
                    display: "No policy required",
                    content: (
                      <p className="small-text mb-24 ml-40 accent-text line-height-double pre-wrap"></p>
                    ),
                  },
                  {
                    value: "custom",
                    display: "Create custom Covid-19 Policy",
                    content: (
                      <React.Fragment>
                        <textarea
                          className="custom-input custom-message mt-8"
                          disabled={!shouldOverridePolicy}
                          value={overridePolicy}
                          placeholder="Type custom policy"
                          name="overridePolicy"
                          onChange={this.onChangeEnrollmentEvt}
                        />
                        {submitAttempted &&
                          shouldOverridePolicy &&
                          !overridePolicy && (
                            <p
                              className="error-message"
                              style={{ paddingLeft: 40 }}
                            >
                              please enter your custom policy
                            </p>
                          )}
                      </React.Fragment>
                    ),
                  },
                ]}
                value={
                  !isPolicyRequired
                    ? false
                    : shouldOverridePolicy
                    ? "custom"
                    : true
                }
              />
            </div>
          </div>
          <div className="program-form-section mb-16">
            <Typography variant="h6" className="mb-8 flex flex-align-center">
              Custom Questions
              <IconButton
                className="ml-8"
                color="primary"
                size="small"
                onClick={() => this.onAddRemoveRegistrationQuestion()}
              >
                <AddCircle fontSize="small" />
              </IconButton>
            </Typography>

            <Typography
              variant="body2"
              style={{ color: "#747579" }}
              className="mb-8"
            >
              Add any questions you feel necessary to be asked when registering.
            </Typography>

            <div>
              {!activeRegistrationQuestions.length ? (
                <p className="accent-text flex flex-align-center">
                  Click the{" "}
                  <i className="material-icons medium-text ml-4 mr-4">
                    add_circle
                  </i>{" "}
                  above to add a custom question
                </p>
              ) : (
                activeRegistrationQuestions.map((question, i) => (
                  <div className="flex flex-align-center mb-16" key={i}>
                    <RsvpCustomQuestion
                      question={question}
                      onChange={this.onChangeEnrollment}
                      onChangeEvent={this.onChangeEnrollmentEvt}
                      submitAttempted={submitAttempted}
                      onAddRemoveQuestion={this.onAddRemoveRegistrationQuestion}
                    />
                  </div>
                ))
              )}
            </div>
          </div>
          <div className="program-form-section mb-16">
            <Typography variant="h6" className="mb-8">
              Your Student Profile Settings
            </Typography>

            {studentProfileSettingsLoading ? (
              <p className="accent-text">Loading settings...</p>
            ) : studentProfileSettingsError ? (
              <p className="error-text small-text">
                Student profile settings could not be retrieved at this time.
                Please try refreshing the page.
              </p>
            ) : (
              <div>
                <Typography variant="body2" style={{ color: "#747579" }}>
                  Choose which fields to require in the student profile such as
                  Gender and Major.
                </Typography>
                <Typography variant="body2" style={{ color: "#747579" }}>
                  Note: only required questions will be asked to students
                  enrolling via SMS.
                </Typography>

                <Button
                  variant="text"
                  color="primary"
                  onClick={() =>
                    this.setState({ showStudentProfileSettingsModal: true })
                  }
                  className="mb-24 mt-16"
                >
                  {this.isShliach ? "MANAGE" : "VIEW"} STUDENT SETTINGS
                </Button>

                <p className="medium-text mb-16">
                  Allergy and Dietary Restrictions
                </p>
                <div style={{ width: 280 }}>
                  <div className="flex flex-justify-space flex-align-center mb-16">
                    <div className="accent-text mr-16">Events</div>
                    <Toggle
                      disabled={!this.isShliach}
                      name={"isAllergiesRequiredForEvents"}
                      onChange={() =>
                        this.onChangeEnrollment(
                          "isAllergiesRequiredForEvents",
                          !isAllergiesRequiredForEvents,
                        )
                      }
                      options={[
                        {
                          value: true,
                          display: "Required",
                        },
                        {
                          value: false,
                          display: "Optional",
                        },
                      ]}
                      value={isAllergiesRequiredForEvents}
                    />
                  </div>
                  <div className="flex flex-justify-space flex-align-center">
                    <div className="accent-text mr-16">Resources</div>
                    <Toggle
                      disabled={!this.isShliach}
                      name={"isAllergiesRequiredForResources"}
                      onChange={() =>
                        this.onChangeEnrollment(
                          "isAllergiesRequiredForResources",
                          !isAllergiesRequiredForResources,
                        )
                      }
                      options={[
                        {
                          value: true,
                          display: "Required",
                        },
                        {
                          value: false,
                          display: "Optional",
                        },
                      ]}
                      value={isAllergiesRequiredForResources}
                    />
                  </div>
                </div>
              </div>
            )}
            <StudentProfileSettingsModal
              chabadHouseId={forChabadHouseID}
              close={() =>
                this.setState({ showStudentProfileSettingsModal: false })
              }
              disabled={!this.isShliach}
              error={submitStudentProfileSettingsError}
              loading={submitStudentProfileSettingsLoading}
              programId={programID}
              studentProfileSettings={studentProfileSettings}
              show={showStudentProfileSettingsModal}
              submitStudentProfileSettings={
                actions.submitStudentProfileSettings
              }
            />
          </div>
          {!newEnrollment && (
            <React.Fragment>
              <div className="program-form-section">
                <Typography variant="h6">
                  Your Chabad House's RSVP system shareable URL
                </Typography>
                <div className="flex flex-align-center tablet-block mobile-block">
                  <div className="full-width">
                    <CopyToClipboardTextbox
                      className="copy-to-clipboard-textbox flex-justify-space flex-align-center mt-16"
                      id="rsvp-events-url"
                      goToLabel="View Link"
                      label="Copy Link"
                      showGoTo={true}
                      text={chabadHouseRsvpURL}
                    />
                    {!!forChabadHouseID && (
                      <Typography
                        variant="body2"
                        style={{ color: "#747579" }}
                        className="mt-8"
                      >
                        The URL Friendly Name (the part of the URL that
                        references your Chabad House) can be updated in your
                        Chabad House profile by clicking{" "}
                        <a
                          className="link-text"
                          href={`/account`} //TODO scroll to chabad house section
                        >
                          here
                        </a>
                      </Typography>
                    )}
                  </div>
                </div>
              </div>
              {isChabadHouseEnrolledInMessaging &&
                chabadHouseMessagingNumber && (
                  <div className="program-form-section">
                    <Typography variant="h6" className="mb-8">
                      Your Messaging Number
                    </Typography>
                    <div className="flex flex-align-center tablet-block mobile-block">
                      <Typography
                        variant="body2"
                        style={{ color: "#747579" }}
                        className="mr-8 mt-16 tablet-mt-8 tablet-mb-8 mobile-mt-8 mobile-mb-8"
                      >
                        Your Chabad House's Automated Messaging Contact Number
                        is
                      </Typography>

                      <Typography className="mt-16">
                        {chabadHouseMessagingNumber}
                      </Typography>
                    </div>
                  </div>
                )}
            </React.Fragment>
          )}
        </div>
        <div className="flex relative program-form-btns mt-24 mb-24">
          {newEnrollment ? (
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={this.onSubmitEnrollment}
            >
              {loading ? "Activating..." : "Activate"}
            </Button>
          ) : (
            <React.Fragment>
              <Button
                variant="contained"
                disabled={loading || !hasChanges}
                onClick={this.onCancelEnrollmentChanges}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                className="ml-24"
                disabled={loading || !hasChanges}
                onClick={this.onSubmitEnrollment}
              >
                {loading ? "Saving..." : "Save"}
              </Button>
            </React.Fragment>
          )}
          {submitAttempted && !loading && errorMessage && (
            <p className="error-message">{errorMessage}</p>
          )}
        </div>
      </div>
    );
  }
}
