import React, { useState, useEffect, useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import SelectSearch from "react-select";
import moment from "moment";
import {
  Card,
  Grid,
  Typography,
  Tooltip,
  Avatar,
  IconButton,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import {
  getJewishInterestLevelColors,
  getJewishObservanceStatusColor,
} from "../engagement/shared";
import {
  JewishnessStatuses,
  JewishInterestLevels,
} from "../../../state/engagement/types";
import {
  getYearOptions,
  removeTimezoneFormatFromDate,
  replaceValuesInObject,
} from "../../../lib";
import CopyToClipboardTextbox from "../../../components/CopyToClipboardTextbox";
import DateTimePicker from "../../../components/DateTimePicker";
import FilePreviewModal from "../../../components/FilePreviewModal";
import Loader from "../../../components/Loader";
import Select from "../../../components/form/CustomSelect";
import PhoneInput from "../../../components/form/PhoneInput";
import ValidatedInput from "../../../components/form/ValidatedInput";
import StudentProfileModal from "../studentProfile/StudentProfileModal";
import StudentJewishnessModal from "../StudentJewishnessModal";
import StudentObservanceModal from "../StudentObservanceModal";
import TargetStudentModal from "../TargetStudentModal";
import {
  formatAddressDisplay,
  formatFullName,
  joinCommaDelimitedList,
  pluralizeText,
} from "../../../lib";
import { useStyles } from "./StudentOverview.styles";
import { useMobile } from "../../../themes";
import { AuthSelectors } from "../../../state";

const graduationYears = getYearOptions(2005, new Date().getFullYear() + 15);
const inlineEditableFields = {
  campus: "campus",
  cell: "cell",
  class: "class",
  dob: "dob",
  graduationYear: "graduationYear",
};

const getJewishnessStatusIcon = (jewishnessStatus) => {
  switch (jewishnessStatus) {
    case JewishnessStatuses.Yes:
      return (
        <i
          className="material-icons"
          style={{
            color: "#77D9D2",
            fontSize: "32px",
          }}
        >
          verified
        </i>
      );
    case JewishnessStatuses.No:
      return <img src="/images/jewishnessNo.svg" alt="x_mark" height="32px" />;
    default:
      return (
        <img
          src="/images/jewishnessUnknown.svg"
          alt="question_mark"
          height="32px"
        />
      );
  }
};

export default function StudentOverview(props) {
  const {
    editStudentAccess,
    getStudentCampuses,
    refreshStudentDetails,
    student,
    student: {
      address,
      allergies,
      allergiesDisplay,
      campus,
      cannotRemoveDobAndCell,
      cell,
      cellCountryID: _cellCountryID,
      class: studentClass,
      classDisplay,
      dateCreated,
      demographics = {},
      didStudentCreateAccount,
      didStudentVerifyCampus,
      didStudentVerifyCell,
      didStudentVerifyGraduationYear,
      didStudentVerifyProfile,
      dietaryNotes,
      email,
      graduationYear,
      hasAllergies,
      id: studentID,
      isTaggedForCurrentMonth,
      isArchived,
      isJewish,
      person: { age, dob, firstName, lastName, profileImageURL } = {},
    } = {},
    sys: {
      countries = [],
      interactionTypes = [],
      jewishBackgroundTypes = [],
      jewishObservanceStatuses = [],
      studentClasses = [],
    } = {},
    goToOnSubmitProfile,
    editDemographicsMode,
    submitStudent,
  } = props;

  const formattedAddress = address
    ? formatAddressDisplay(
        address.address1,
        `${address.address2 ? `${address.address2},` : ""}`,
        //hard-coded comma because formatAddress prints it on 2 separate lines and here we're putting the entire address on one line
        address.city,
        address.state,
        address.zip,
        address.country,
      )
    : null;

  const isShliach = useSelector(AuthSelectors.isLoggedInAsShliach);
  const { isEnrolledInEngagement } = useSelector(
    AuthSelectors.engagementAccess,
  );
  const submittedForGrantText = isShliach
    ? " because this student has been submitted for a Lamplighters grant"
    : "";

  const [showProfileImagePreview, setShowProfileImagePreview] = useState(false);
  const [showStudentProfileModal, setShowStudentProfileModal] = useState(false);
  const [showStudentJewishnessModal, setShowStudentJewishnessModal] =
    useState(false);
  const [showStudentObservanceModal, setShowStudentObservanceModal] =
    useState(false);
  const [showTargetStudentModal, setShowTargetStudentModal] = useState(false);
  const [inlineEditField, setInlineEditField] = useState();
  const [inlineEditValue, setInlineEditValue] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [loading, setLoading] = useState(false);
  const [campuses, setCampuses] = useState();

  //this info is tracked for when phone # is edited inline
  const inlineEditCellPhoneValid = useRef(true);
  const inlineEditCellCountryID = useRef(_cellCountryID);

  const openInlineEditField = useCallback(
    async (name, value) => {
      setErrorMessage();
      setInlineEditField(name);
      setInlineEditValue(value);
      if (name === "campus" && !campuses) {
        const data = await getStudentCampuses();
        if (data.payload) {
          setCampuses(data.payload);
        }
      }
    },
    [campuses, getStudentCampuses],
  );
  const clearInlineEditField = useCallback(() => {
    setInlineEditField();
    setInlineEditValue();
    setErrorMessage();
    inlineEditCellPhoneValid.current = true;
  }, []);

  const onChangePhoneValidation = (isValid) => {
    inlineEditCellPhoneValid.current = isValid;
  };

  const onChangeInlineEditValue = useCallback(
    (name, value) => {
      if (inlineEditValue !== value) {
        setInlineEditValue(value);
        if (errorMessage) {
          setErrorMessage();
        }
      }
    },
    [errorMessage, inlineEditValue],
  );
  const saveInlineEditField = useCallback(async () => {
    setErrorMessage();
    if (!inlineEditCellPhoneValid.current) {
      //TODO: This is a temporary workaround for the phone input that should be removed after COC-4199
      setErrorMessage("");
      return;
    }

    if (!inlineEditValue) {
      if (
        inlineEditField === inlineEditableFields.cell &&
        cannotRemoveDobAndCell
      ) {
        setErrorMessage(
          `Cellphone number may be replaced but not removed${submittedForGrantText}.`,
        );
        return;
      }
      if (
        inlineEditField === inlineEditableFields.dob &&
        cannotRemoveDobAndCell
      ) {
        setErrorMessage(
          `Birthday may be replaced but not removed${submittedForGrantText}.`,
        );
        return;
      } else if (inlineEditField === inlineEditableFields.campus) {
        setErrorMessage("School is required");
        return;
      }
    }
    const initialValue = student[inlineEditField];
    if (
      initialValue !== inlineEditValue ||
      inlineEditCellCountryID.current !== _cellCountryID
    ) {
      setLoading(true);
      let updatedStudent;

      if (inlineEditField === inlineEditableFields.dob) {
        updatedStudent = {
          ...student,
          person: { ...student.person, dob: inlineEditValue },
        };
      } else if (inlineEditField === inlineEditableFields.cell) {
        updatedStudent = {
          ...student,
          cell: inlineEditValue,
          cellCountryID: inlineEditCellCountryID.current,
        };
      } else {
        updatedStudent = {
          ...student,
          [inlineEditField]: inlineEditValue,
        };
      }

      replaceValuesInObject(
        updatedStudent,
        (val) => val === "",
        () => null,
      );

      const result = await submitStudent(updatedStudent);
      if (result?.success) {
        await refreshStudentDetails();
        clearInlineEditField();
      } else {
        setErrorMessage(result?.errorMessage || "Sorry, something went wrong");
      }
      setLoading(false);
    } else {
      clearInlineEditField();
    }
  }, [
    cannotRemoveDobAndCell,
    _cellCountryID,
    inlineEditField,
    inlineEditValue,
    refreshStudentDetails,
    student,
    submitStudent,
    submittedForGrantText,
    clearInlineEditField,
  ]);

  const classes = useStyles();
  const isMobile = useMobile();

  const profileEditIcons = (
    <>
      {isShliach && isEnrolledInEngagement && (
        <Tooltip
          arrow
          title={
            demographics?.jewishInterestLevel !== JewishInterestLevels.Other
              ? "Tag student"
              : "Can’t tag because status is 'Other'"
          }
        >
          <IconButton
            className="mr-4"
            style={{
              padding: "2px",
              opacity:
                demographics?.jewishInterestLevel === JewishInterestLevels.Other
                  ? 0.25
                  : 1,
            }}
            aria-label="target"
            onClick={() => {
              if (
                demographics?.jewishInterestLevel !== JewishInterestLevels.Other
              ) {
                setShowTargetStudentModal(true);
              }
            }}
          >
            {isTaggedForCurrentMonth ? (
              <img
                src="/images/targeted_month.svg"
                alt="x_mark"
                height="32px"
              />
            ) : (
              <img src="/images/target.svg" alt="x_mark" height="32px" />
            )}
          </IconButton>
        </Tooltip>
      )}
      <Tooltip
        title={
          !editStudentAccess
            ? "You do not have access to edit this student because s/he is not enrolled at a campus associated with your Chabad House"
            : ""
        }
      >
        <IconButton
          style={{
            padding: "2px",
          }}
          aria-label="edit"
          disabled={!editStudentAccess}
          onClick={() => setShowStudentProfileModal(true)}
        >
          <i className="material-icons">edit</i>
        </IconButton>
      </Tooltip>
    </>
  );

  const inlineEditComponent = useCallback(
    ({ child, icon, styling, className }) => {
      return (
        <div
          className={`flex flex-align-center ${className || ""}`}
          style={{ zIndex: 9999, ...styling }}
        >
          {loading ? (
            <div className="image-upload-loader mr-32">
              <Loader />
            </div>
          ) : (
            <div>
              <div className="flex flex-align-center">
                <i className="material-icons mr-8 large-text">{icon}</i>
                {child}
                <i
                  className="pointer material-icons inline-edit-icons"
                  style={{
                    color: "#77D9D2",
                  }}
                  onClick={saveInlineEditField}
                >
                  check
                </i>
                <i
                  className="pointer material-icons inline-edit-icons"
                  style={{
                    color: "#FF8585",
                  }}
                  onClick={clearInlineEditField}
                >
                  close
                </i>
              </div>
              {errorMessage && (
                <div className="mt-4 error-text">{errorMessage}</div>
              )}
            </div>
          )}
        </div>
      );
    },
    [errorMessage, loading, saveInlineEditField, clearInlineEditField],
  );

  useEffect(() => {
    if (editDemographicsMode) {
      setShowStudentProfileModal(true);
    }
  }, [editDemographicsMode]);

  const limitedStudentVerifiedProfileFields = !didStudentVerifyProfile
    ? [
        didStudentVerifyCampus && "Campus",
        didStudentVerifyCell && "Cell",
        didStudentVerifyGraduationYear && "Graduating year",
      ].filter((v) => v)
    : [];

  return (
    <>
      {(!didStudentCreateAccount || !didStudentVerifyProfile) && (
        <Alert severity="info" className={classes.alert}>
          {!didStudentCreateAccount
            ? "An account has not yet been created by the student."
            : limitedStudentVerifiedProfileFields.length
            ? `Only the ${joinCommaDelimitedList(
                limitedStudentVerifiedProfileFields,
              )} ${
                limitedStudentVerifiedProfileFields.length > 1 ? "have" : "has"
              } been verified by the student. The rest of the student profile has not yet been verified.`
            : "Student profile has not yet been verified by the student."}{" "}
          Note that {!didStudentCreateAccount ? "" : "unverified "}profile
          fields will not be visible to the student until the profile is
          verified, and will be overridden by the student upon verification.
        </Alert>
      )}
      <Card
        variant={isMobile ? undefined : "outlined"}
        className={classes.cardContainer}
      >
        <Grid
          container
          item
          justifyContent="space-between"
          className="student-overview-header"
        >
          <div className="flex flex-align-center mobile-block tablet-block mobile-mb-16">
            <Typography variant="h2" className="mb-8 flex flex-align-top">
              <Avatar
                className={classes.profileImage}
                alt="profile"
                onClick={() => setShowProfileImagePreview(true)}
                src={profileImageURL}
              />

              <span
                className="xs-text mt-32 ml-0"
                style={{
                  color: isArchived ? "#B4B5B9" : "#33b7ae",
                  marginLeft: "-12px",
                  zIndex: 999,
                  fontSize: "10px",
                }}
              >
                {"\u2B24"}
              </span>
              <div className="ml-16">{formatFullName(firstName, lastName)}</div>
              <div className="desktop-hidden tablet-hidden flex flex-align-center mobile-ml-16">
                {profileEditIcons}
              </div>
            </Typography>
            {isShliach && isEnrolledInEngagement && (
              <>
                <div className="flex flex-justify-space">
                  <div
                    className={`student-overview-icons editable-icons mobile-full-width flex flex-justify-center ${
                      editStudentAccess ? "pointer" : ""
                    }`}
                    onClick={
                      editStudentAccess
                        ? () => setShowStudentJewishnessModal(true)
                        : undefined
                    }
                  >
                    {getJewishnessStatusIcon(demographics.jewishnessStatus)}
                  </div>
                  <Tooltip title="Student interest level is calculated based on activity">
                    <div className="student-overview-icons mobile-full-width flex flex-justify-center">
                      <i
                        className="material-icons accent-text-light mr-8"
                        style={{
                          color: getJewishInterestLevelColors(
                            demographics.jewishInterestLevel,
                          ).dark,
                          fontSize: "32px",
                        }}
                      >
                        favorite
                      </i>
                      <span className="mr-4">
                        {demographics.jewishInterestLevel}
                      </span>
                    </div>
                  </Tooltip>
                </div>
                <div className="flex">
                  <div
                    className={`student-overview-icons editable-icons mobile-full-width flex flex-justify-center ${
                      editStudentAccess ? "pointer" : ""
                    }`}
                    onClick={
                      editStudentAccess
                        ? () => setShowStudentObservanceModal(true)
                        : undefined
                    }
                  >
                    <i
                      className="material-icons mr-8"
                      style={{
                        color: getJewishObservanceStatusColor(
                          demographics.currentJewishObservanceStatus,
                        ).dark,
                        fontSize: "32px",
                      }}
                    >
                      location_on
                    </i>
                    <div>
                      <p className="mr-4">
                        {demographics.currentJewishObservanceStatus}
                      </p>
                      <p className="mr-4 accent-text xs-text">
                        Current Observance
                      </p>
                    </div>
                  </div>
                  <div
                    className={`student-overview-icons editable-icons mobile-full-width flex flex-justify-center ${
                      editStudentAccess ? "pointer" : ""
                    }`}
                    onClick={
                      editStudentAccess
                        ? () => setShowStudentObservanceModal(true)
                        : undefined
                    }
                  >
                    <i
                      className="material-icons mr-8"
                      style={{
                        color: getJewishObservanceStatusColor(
                          demographics.childhoodJewishObservanceStatus,
                        ).dark,
                        fontSize: "32px",
                      }}
                    >
                      escalator_warning
                    </i>
                    <div>
                      <p className="mr-4">
                        {demographics.childhoodJewishObservanceStatus}
                      </p>
                      <p className="mr-4 accent-text xs-text">
                        Childhood Observance
                      </p>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <Grid item className="mobile-hidden">
            {profileEditIcons}
          </Grid>
        </Grid>
        {profileImageURL && (
          <FilePreviewModal
            close={() => setShowProfileImagePreview(false)}
            previewStyle={{ objectFit: "contain" }}
            show={showProfileImagePreview}
            title={`${formatFullName(firstName, lastName)} Profile`}
            url={profileImageURL}
          />
        )}
        <div className="student-overview-grid">
          <Grid container item justifyContent="space-between" spacing={3}>
            <Grid item className="student-overview-grid-item">
              <div className="uppercase-text accent-text-dark fw-500 mb-12 student-overview-title">
                Contact info
              </div>
              <div className="student-overview-field non-editable-icon ml-8">
                <i className="material-icons mr-12 large-text">email</i>
                {email || "--"}
              </div>
              {inlineEditField === inlineEditableFields.cell ? (
                <>
                  {inlineEditComponent({
                    styling: { marginBottom: "18px" },
                    child: (
                      <div
                        className={`tooltip-container ${
                          !inlineEditCellPhoneValid.current ||
                          (cannotRemoveDobAndCell && !inlineEditValue)
                            ? "error"
                            : ""
                        }`}
                      >
                        <PhoneInput
                          className="inline-edit-input"
                          countries={countries}
                          countryId={inlineEditCellCountryID.current}
                          defaultCountryName={address && address.country} //fallback on country associated with student's address
                          error={!inlineEditCellPhoneValid.current}
                          name="cell"
                          onBlur={(_, isValid) =>
                            onChangePhoneValidation(isValid)
                          }
                          onChange={onChangeInlineEditValue}
                          onChangeCountry={(countryId) => {
                            inlineEditCellCountryID.current = countryId;
                          }}
                          value={inlineEditValue}
                          autoFocus
                        />
                      </div>
                    ),
                    icon: "phone",
                    className: "phone-input-edit-field",
                  })}
                </>
              ) : (
                <Typography
                  variant="body2"
                  className={`${
                    editStudentAccess ? "pointer" : ""
                  } student-overview-field`}
                  onClick={(e) => {
                    e.preventDefault();
                    if (editStudentAccess) {
                      openInlineEditField(inlineEditableFields.cell, cell);
                    }
                  }}
                >
                  <i className="material-icons mr-4 ml-8 large-text">phone</i>
                  <a
                    className={`${
                      editStudentAccess ? "inline-edit-container" : ""
                    } link-text-secondary medium-text`}
                    href={`tel:${cell}`}
                  >
                    {cell || "--"}
                  </a>
                </Typography>
              )}

              <Typography
                variant="body2"
                className="student-overview-field flex-align-top ml-6"
              >
                <i className="material-icons mr-10 large-text mt-2">home</i>
                {formattedAddress || "--"}
              </Typography>
            </Grid>

            <Grid item className="student-overview-grid-item">
              <div className="uppercase-text accent-text-dark fw-500 mb-12 student-overview-title">
                School Info
              </div>
              {inlineEditField === inlineEditableFields.campus ? (
                inlineEditComponent({
                  styling: { marginTop: "-6px", marginBottom: "12px" },
                  child: (
                    <div className="full-width">
                      <ValidatedInput
                        input={
                          <SelectSearch
                            className="inline-edit-input"
                            isClearable={true}
                            isSearchable={true}
                            options={campuses?.map((ca) => ({
                              label: ca.name,
                              value: ca.id,
                            }))}
                            placeholder="Select School"
                          />
                        }
                        onChange={(campus) =>
                          onChangeInlineEditValue(
                            "campus",
                            campus
                              ? {
                                  id: campus.value,
                                  name: campus.label,
                                }
                              : null,
                          )
                        }
                        required={true}
                        value={
                          inlineEditValue && {
                            label: inlineEditValue.name,
                            value: inlineEditValue.id,
                          }
                        }
                      />
                    </div>
                  ),
                  icon: "business",
                })
              ) : (
                <p
                  className={`${
                    editStudentAccess ? "pointer" : ""
                  } student-overview-field flex-align-top`}
                  onClick={
                    editStudentAccess
                      ? () => {
                          openInlineEditField(
                            inlineEditableFields.campus,
                            campus,
                          );
                        }
                      : undefined
                  }
                >
                  <i className="material-icons mr-8 large-text mt-4">
                    business
                  </i>
                  <span
                    className={editStudentAccess ? "inline-edit-container" : ""}
                  >
                    {campus?.name || "--"}
                  </span>
                </p>
              )}
              {inlineEditField === inlineEditableFields.class ? (
                inlineEditComponent({
                  styling: { marginTop: "-4px", marginBottom: "14px" },
                  child: (
                    <div className="full-width">
                      <ValidatedInput
                        input={
                          <Select
                            className="inline-edit-input"
                            isClearable={true}
                            options={studentClasses.map((cl) => ({
                              label: cl.displayValue,
                              value: cl.enumValue,
                            }))}
                            placeholder="Select Demographic"
                            searchable={true}
                          />
                        }
                        name="class"
                        onChange={onChangeInlineEditValue}
                        value={inlineEditValue}
                      />
                    </div>
                  ),
                  icon: "label",
                })
              ) : (
                <p
                  className={`${
                    editStudentAccess ? "pointer" : ""
                  } student-overview-field`}
                  onClick={
                    editStudentAccess
                      ? () => {
                          openInlineEditField(
                            inlineEditableFields.class,
                            studentClass,
                          );
                        }
                      : undefined
                  }
                >
                  <i className="material-icons mr-8 large-text">label</i>
                  <span
                    className={editStudentAccess ? "inline-edit-container" : ""}
                  >
                    {classDisplay || "--"}
                  </span>
                </p>
              )}
              {inlineEditField === inlineEditableFields.graduationYear ? (
                inlineEditComponent({
                  styling: { marginTop: "-4px", marginBottom: "14px" },
                  child: (
                    <div className="full-width">
                      <ValidatedInput
                        input={
                          <Select
                            className="inline-edit-input"
                            isClearable={true}
                            options={graduationYears.map((year) => ({
                              value: year,
                              label: year,
                            }))}
                            placeholder="Select Year"
                            searchable={true}
                          />
                        }
                        name="graduationYear"
                        onChange={onChangeInlineEditValue}
                        value={inlineEditValue}
                      />
                    </div>
                  ),
                  icon: "school",
                })
              ) : (
                <p
                  className={`${
                    editStudentAccess ? "pointer" : ""
                  } student-overview-field`}
                  onClick={
                    editStudentAccess
                      ? () => {
                          openInlineEditField(
                            inlineEditableFields.graduationYear,
                            graduationYear,
                          );
                        }
                      : undefined
                  }
                >
                  <i className="material-icons mr-8 large-text">school</i>
                  <span
                    className={editStudentAccess ? "inline-edit-container" : ""}
                  >
                    {graduationYear || "--"}
                  </span>
                </p>
              )}
            </Grid>

            <Grid item className="student-overview-grid-item">
              <>
                <div className="uppercase-text accent-text-dark fw-500 mb-12 student-overview-title">
                  General Info
                </div>

                {
                  <p className="student-overview-field">
                    <i className="material-icons mr-10 large-text">badge</i>
                    {isJewish !== null
                      ? `Student identifies as ${
                          isJewish ? "Jewish" : "non-Jewish"
                        }`
                      : "--"}
                  </p>
                }

                {inlineEditField === inlineEditableFields.dob ? (
                  inlineEditComponent({
                    styling: { marginTop: "-4px", marginBottom: "14px" },
                    child: (
                      <div className="tooltip-container relative">
                        <ValidatedInput
                          className="input-container inline-edit-input"
                          input={
                            <DateTimePicker
                              maxDate={new Date()}
                              scrollableYearDropdown={true}
                              showMonthDropdown={true}
                              showYearDropdown={true}
                              yearDropdownItemNumber={40}
                              placeholder="Enter Birthday"
                            />
                          }
                          name="person.dob"
                          onChange={(_, value) =>
                            onChangeInlineEditValue(
                              inlineEditableFields.dob,
                              removeTimezoneFormatFromDate(value),
                            )
                          }
                          required={cannotRemoveDobAndCell}
                          value={inlineEditValue}
                        />
                      </div>
                    ),
                    icon: "cake",
                  })
                ) : (
                  <p
                    className={`${
                      editStudentAccess ? "pointer" : ""
                    } student-overview-field`}
                    onClick={
                      editStudentAccess
                        ? () => {
                            openInlineEditField(inlineEditableFields.dob, dob);
                          }
                        : undefined
                    }
                  >
                    <i
                      className="material-icons large-text"
                      style={{ marginRight: "5px" }}
                    >
                      cake
                    </i>
                    <Tooltip title={age ? `Age: ${age}` : ""} arrow>
                      <span
                        className={
                          editStudentAccess ? "inline-edit-container" : ""
                        }
                      >
                        {dob ? moment(dob).format("MMMM D, YYYY") : "--"}
                      </span>
                    </Tooltip>
                  </p>
                )}
                {
                  <p className="student-overview-field">
                    <img
                      className="mr-8"
                      src="/images/allergies.svg"
                      alt="allergies"
                    />

                    <Tooltip
                      title={
                        allergies.length > 0 || !!dietaryNotes?.trim() ? (
                          <div>
                            {allergies.length > 0 ? (
                              <div>
                                Allergies: {allergiesDisplay.join(", ")}
                              </div>
                            ) : (
                              ""
                            )}
                            {!!dietaryNotes?.trim() ? (
                              <div>Info: {dietaryNotes}</div>
                            ) : (
                              ""
                            )}
                          </div>
                        ) : (
                          ""
                        )
                      }
                      arrow
                    >
                      <span>
                        {allergies.length
                          ? `${allergies.length}
                        ${pluralizeText(
                          "allergy",
                          allergies.length,
                          "allergies",
                        )}`
                          : hasAllergies === false
                          ? `No allergies${!!dietaryNotes ? "*" : ""}`
                          : "--"}
                      </span>
                    </Tooltip>
                  </p>
                }
              </>
            </Grid>
          </Grid>
          <div className="flex flex-align-center mobile-block mb-16">
            <div className="flex flex-align-center accent-text">
              Joined:{" "}
              {dateCreated ? moment(dateCreated).format("MMMM D, YYYY") : "—"}
            </div>
            <div className="flex flex-align-center accent-text mobile-mt-16">
              <span className="mobile-hidden ml-4 mr-4"> &#x2022;</span>
              COC Student ID:
              <CopyToClipboardTextbox
                className={classes.copyToClipboard}
                id="coc-student-id"
                label="Copy"
                text={studentID}
              />
            </div>
          </div>
        </div>
        {showStudentProfileModal && (
          <StudentProfileModal
            close={() => setShowStudentProfileModal(false)}
            onSubmit={refreshStudentDetails}
            goToOnSubmitProfile={goToOnSubmitProfile}
            editDemographicsMode={editDemographicsMode}
            show={showStudentProfileModal}
            studentProfile={student}
          />
        )}
        {showStudentJewishnessModal && (
          <StudentJewishnessModal
            show={showStudentJewishnessModal}
            close={() => setShowStudentJewishnessModal(false)}
            onSubmit={refreshStudentDetails}
            student={student}
            jewishBackgroundTypes={jewishBackgroundTypes}
            getJewishnessStatusIcon={getJewishnessStatusIcon}
            submitStudent={submitStudent}
          />
        )}
        {showStudentObservanceModal && (
          <StudentObservanceModal
            show={showStudentObservanceModal}
            close={() => setShowStudentObservanceModal(false)}
            onSubmit={refreshStudentDetails}
            student={student}
            jewishObservanceStatuses={jewishObservanceStatuses}
            submitStudent={submitStudent}
          />
        )}
        {showTargetStudentModal && (
          <TargetStudentModal
            show={showTargetStudentModal}
            close={() => setShowTargetStudentModal(false)}
            student={student}
            interactionTypes={interactionTypes}
            onSubmit={refreshStudentDetails}
          />
        )}
      </Card>
    </>
  );
}
