import React from "react";
import Select from "react-select";

import { Navigation } from "../../../lib";

import TripInfoCard from "./TripInfoCard";

export default class TripSidebar extends React.PureComponent {
  state = {
    selectedTrip: null,
  };

  async componentDidMount() {
    await this.props.getHistoricTripEnrollments(this.props.shliachID);
    this.setSelectedTrip();
  }

  componentWillUnmount() {
    this._ismounted = false;
  }

  componentDidUpdate(prevProps) {
    if (this.props.pageRoute !== prevProps.pageRoute) {
      const {
        query: { tab },
      } = this.props.pageRoute;
      const {
        query: { tab: prevTab },
      } = prevProps.pageRoute;

      if (tab !== prevTab) {
        this.setState({ tabContentShowing: tab });
      }
    }
  }

  navigate = (page) => {
    Navigation.go(`${this.props.pageRoute.location.pathname}?tab=${page}`);
  };

  setSelectedTrip = () => {
    const currentTrip =
      this.props.trip.historicEnrollments &&
      this.props.trip.historicEnrollments
        .filter((enrollment) => enrollment.eventID === this.props.trip.event.id)
        .map((enrollment) => ({
          key: enrollment,
          value: enrollment,
          label: enrollment.title,
        }))[0];
    this.setState({ selectedTrip: currentTrip });
  };

  onChange = (value) => {
    value = value.value;
    this.getHistoricTrip(value.programScheduleID);
  };

  getHistoricTrip = (scheduleID) => {
    const { pageRoute, tabContentShowing } = this.props;
    const pageURL = pageRoute.location.pathname.slice(
      0,
      pageRoute.location.pathname.lastIndexOf("/"),
    );
    Navigation.go(
      `${pageURL}/${scheduleID}?tab=${
        tabContentShowing === undefined ? "students" : tabContentShowing
      }`,
    );
  };

  render() {
    const {
      showMenu,
      tabContentShowing,
      trip: { historicEnrollments },
    } = this.props;
    const { selectedTrip } = this.state;

    return (
      <div className="mr-24">
        <div className="card trip-sidebar-card mb-32">
          <Select
            className={`custom-select`}
            classNamePrefix="custom-select"
            name={"selectedTrip"}
            onChange={(option) => this.onChange(option)}
            options={
              historicEnrollments &&
              historicEnrollments.map((enrollment) => ({
                key: enrollment,
                value: enrollment,
                label: enrollment.title,
              }))
            }
            placeholder={"Select past trip..."}
            styles={{
              indicatorSeparator: (styles) => ({ ...styles, display: "none" }),
              placeholder: (styles) => ({ ...styles, fontSize: "12px" }),
              clearIndicator: (styles) => ({
                ...styles,
                marginRight: "-8px",
                padding: "0",
              }),
            }}
            value={selectedTrip}
          />
        </div>
        {showMenu && (
          <div className="card trip-sidebar-card trip-sidebar-menu-card">
            <ul>
              <li>
                <p
                  className={`trip-link ${
                    tabContentShowing === "students" ? "active-trip-link" : ""
                  }`}
                  name="students"
                  onClick={() => this.navigate("students")}
                >
                  Students
                </p>
              </li>
              <li className="mt-24 mb-24">
                <p
                  name="metrics"
                  className={`trip-link ${
                    tabContentShowing === "metrics" ? "active-trip-link" : ""
                  }`}
                  onClick={() => this.navigate("metrics")}
                >
                  Metrics
                </p>
              </li>
              <li>
                <p
                  name="settings"
                  className={`trip-link ${
                    tabContentShowing === "settings" ? "active-trip-link" : ""
                  }`}
                  onClick={() => this.navigate("settings")}
                >
                  Settings
                </p>
              </li>
            </ul>
          </div>
        )}
        <TripInfoCard
          studentRegistrationPreviewUrl={
            this.props.studentRegistrationPreviewUrl
          }
          trip={this.props.trip}
          getTripSchedule={this.props.getTripSchedule}
        />
      </div>
    );
  }
}
