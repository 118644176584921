import React from "react";
import { Prompt } from "react-router-dom";
import CancelRecurringSettingsModal from "./CancelRecurringSettingsModal";
import ConfirmationModal from "../../../../../../components/ConfirmationModal";
import CopyToClipboardTextbox from "../../../../../../components/CopyToClipboardTextbox";
import DateTimePicker from "../../../../../../components/DateTimePicker";
import ImageUpload from "../../../../../../components/formik/ImageWithCropUpload";
import TagInput from "../../../../../../components/form/TagInput";
import Select from "react-select";
import NumberFormat from "react-number-format";
import TimePicker from "../../../../../../components/TimePicker";
import Toggle from "../../../../../../components/form/Toggle";
import UpdateRecurringSettingsModal from "./UpdateRecurringSettingsModal";
import Checkbox from "../../../../../../components/form/Checkbox";
import MultiSelect from "../../../../../../components/form/MultiSelect";
import RsvpCustomQuestion from "../../../settings/RsvpCustomQuestion";
import EventInteractionButton from "./EventInteractionButton";
import {
  Button,
  FormControlLabel,
  RadioGroup,
  Radio,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  getFormattedValuesForForm,
  flattenArray,
  removeEmptyFromObj,
  removeTimezoneFormatFromDate,
  Navigation,
  PageURL,
  partition,
  AuthTypes,
  canManageActivity,
} from "../../../../../../lib";
import {
  rsvpMessageTypes,
  sendEventSmsMessage,
} from "../../../RsvpMessageUtils";
import { getFieldOptionsForProgramType } from "../../../RsvpUtils";
import _cloneDeep from "lodash.clonedeep";
import _isEqual from "lodash.isequal";
import _set from "lodash.set";
import ReminderSettings from "./ReminderSettings";
import { REMINDER_SETTING_TYPES } from "../../../../RsvpHelpers";
import ReservationConfirmationMessageSettings from "../../../shared/manage/ReservationConfirmationMessageSettings";

export default class EventSettings extends React.PureComponent {
  constructor(props) {
    super(props);

    const state = {
      ...this.getInitializedSettings(),
      bypassUpdateRecurringSettingsPrompt: false,
      bypassUnlimitedCapacityPrompt: false,
      bypassCloneRecurringWarning: false,
      errorMessage: "",
      studentsReminderError: false,
      requestedNavigation: false,
      showCancelScheduleConfirmationModal: false,
      showCancelRecurringSettingsModal: false,
      showUnlimitedCapacityConfirmationModal: false,
      showUpdateRecurringSettingsModal: false,
      showNotifyStudentsAboutEventUpdateModal: false,
      submitAction: null,
      submitAttempted: false,
      showResumeModal: false,
      showPauseModal: false,
      showCloneRecurringWarningModal: false,
    };

    state.initialState = _cloneDeep(state);

    this.state = state;

    this.isShliach = props.auth.loggedInAs === AuthTypes.shliach;
    this.canManageActivity = canManageActivity(props.auth);
  }

  reinitializeSettings = () => {
    const updatedState = {
      ...this.state.initialState,
      ...this.getInitializedSettings(),
    };
    updatedState.initialState = _cloneDeep(updatedState);
    this.setState(updatedState);
  };

  componentDidMount() {
    // retrieve all chabad house tags for permission settings
    this.props.actions.getAllChabadHouseTags();
  }

  componentDidUpdate(prevProps) {
    if (
      !_isEqual(this.props.eventSchedule.data, prevProps.eventSchedule.data)
    ) {
      this.reinitializeSettings();
    }
  }

  incompleteErrorMessage = "Please complete required or invalid fields";

  actionTypes = {
    cancel: "cancel",
    submit: "submit",
  };

  settingState = {
    active: "Active",
    paused: "Paused",
    canceled: "Canceled",
  };

  getInitializedSettings = () => {
    const {
      enrollment: { data: { timezoneID, registrationQuestions } = {} },
      eventSchedule: { data: eventSchedule = {} },
      isNew,
      pageRoute: {
        query: { cloneEventScheduleId },
      },
    } = this.props;

    let initializedSettings;
    let originalScheduleType;
    if (cloneEventScheduleId) {
      initializedSettings = getFormattedValuesForForm(eventSchedule);
      originalScheduleType = initializedSettings.scheduleType;
      initializedSettings.name = `Copy of ${initializedSettings.name}`;
      //if original event is upcoming don't clone sms codes
      if (eventSchedule.occurrenceStatus === "Upcoming") {
        initializedSettings.smsCodes = [];
      } else {
        initializedSettings.smsCodes = initializeSmsCodes();
      }
      if (initializedSettings.scheduleType === "Recurring") {
        initializedSettings.scheduleType = null;
      }
      initializedSettings.eventDateTime = null;
    } else if (isNew) {
      initializedSettings = {
        accessTags: [],
        abbreviatedName: "",
        address: {
          address1: "",
          address2: "",
          city: "",
          state: "",
          zip: "",
          country: "",
          locationName: "",
        },
        registrationQuestions: [],
        allRegistrationQuestions: registrationQuestions,
        askForDonation: false,
        customConfirmationMessage: null,
        daysOfWeek: [],
        description: "",
        duration: "",
        eventDateTime: "",
        registrationFeeAmount: null,
        imageURL: "",
        isIsraelRelated: false,
        isPrivate: false,
        location: "ChabadHouse",
        maxCapacity: "",
        name: "",
        registrationEndMinutes: 0,
        reminderSettingType: REMINDER_SETTING_TYPES.global,
        registeredStudentsReminderMinutes: null,
        unregisteredStudentsReminderMinutes: null,
        scheduleType: "OneTime",
        shortDescription: "",
        tables: [],
        timeMinutes: "",
        timeHours: "",
        timezoneID, //must set timezoneID for class dateTime conversion
        typeID: "",
        updateSettings: false,
      };
    } else {
      initializedSettings = getFormattedValuesForForm(eventSchedule);
      //sorting sms codes so that the primary one is first
      initializedSettings.smsCodes = initializeSmsCodes();
    }

    return {
      settings: initializedSettings,
      originalScheduleType,
      overrideDefaultConfirmationMessage:
        initializedSettings.customConfirmationMessage ? "true" : "false",
    };

    function initializeSmsCodes() {
      return initializedSettings.smsCodes?.length
        ? [
            initializedSettings.smsCodes.find((c) => c.isPrimary).code,
            ...initializedSettings.smsCodes
              .filter((c) => !c.isPrimary)
              .map((c) => c.code),
          ]
        : [];
    }
  };

  onAddRemoveTable = (sortOrder) => {
    const { settings } = this.state;
    let tables = _cloneDeep(settings.tables);

    if (!sortOrder) {
      //add table
      tables.push({
        capacity: "",
        name: "",
        sortOrder: tables.length + 1,
      });
    } else {
      //remove table,
      tables = tables.filter((t) => t.sortOrder !== sortOrder);

      tables = tables
        .sort((t1, t2) => (t1.sortOrder > t2.sortOrder ? 1 : -1))
        .map((table, tIndex) => ({
          ...table,
          sortOrder: tIndex + 1,
        }));
    }

    this.onChangeSettings("tables", tables);
  };

  onAddRemoveQuestion = (questionIndex) => {
    let additionalQuestions = _cloneDeep(
      this.state.settings.registrationQuestions,
    );

    if (questionIndex >= 0) {
      //delete question

      //remove the question
      additionalQuestions = additionalQuestions.filter(
        (_, qIndex) => questionIndex !== qIndex,
      );
    } else {
      //add question
      additionalQuestions = [
        ...additionalQuestions,
        {
          isActive: true,
          isEventSpecific: false,
          isResourceSpecific: false,
          isMandatory: false,
          questionText: "",
          responseType: "",
        },
      ];
    }

    this.onChangeSettings("registrationQuestions", additionalQuestions);
  };

  onAddRemoveRecurringDayOfWeek = (dow) => {
    let daysOfWeek = _cloneDeep(this.state.settings.daysOfWeek) || [];

    if (daysOfWeek.indexOf(dow) < 0) {
      //add day
      daysOfWeek = [...daysOfWeek, dow];
    } else {
      //remove day
      daysOfWeek = daysOfWeek.filter((d) => d !== dow);
    }

    this.onChangeSettings("daysOfWeek", daysOfWeek);
  };

  goToChabadHouseActivitySettings = () => {
    const { chabadHouseId } = this.state.settings;

    Navigation.go(
      PageURL.to(
        // chabadHouseId
        //   ? `/account/chabadHouse/${chabadHouseId}`:
        "/account", //TODO scroll to chabad house section
        null,
        // {
        //   tab: "activities",
        // },
      ),
    );
  };

  onChangeSettings = (name, value, otherChanges) => {
    if (this.props.submitEvent.loading) {
      return;
    }

    let settings = _cloneDeep(this.state.settings);
    _set(settings, name, value);

    if (otherChanges) {
      Object.keys(otherChanges).forEach((change) =>
        _set(settings, change, otherChanges[change]),
      );
    }
    return new Promise((resolve, reject) => {
      this.setState({ settings }, () => {
        resolve();
        //if required fields message is shown, re-validate on change
        const { errorMessage } = this.state;
        if (errorMessage && errorMessage === this.incompleteErrorMessage) {
          const isValid = this.validateSettings();
          if (isValid) {
            this.setState({
              errorMessage: "",
            });
          }
        }
      });
    });
  };

  onChangeSettingsEvt = ({ target: { name, type, value } }) => {
    //do not allow negative number inputs
    if (type === "number" && value && value < 0) {
      value = "";
    }
    if (name === "abbreviatedName") {
      value = value.toUpperCase();
    }
    return this.onChangeSettings(name, value);
  };

  handleConfirmResume = () => {
    this.updateSettingState(this.settingState.active);
    this.setState({ showResumeModal: false });
  };
  handleConfirmPause = () => {
    this.updateSettingState(this.settingState.paused);
    this.setState({ showPauseModal: false });
  };

  handleUpdateResponse = () => {
    const {
      pageRoute: { page, params },
      submitEvent: { error, errorMessage },
    } = this.props;

    if (error) {
      this.setState({
        errorMessage:
          errorMessage || "Something went wrong.  Please try again.",
      });
    } else {
      this.setState({ requestedNavigation: true }, () => {
        Navigation.go(
          PageURL.to(
            page,
            {
              pageView: "events",
              programScheduleId: params.programScheduleId,
            },
            null,
          ),
        );
      });
    }
  };

  onChangeState = (name, value) => {
    this.setState({ [name]: value });
  };

  updateSettingState = async (eventSettingState) => {
    const eventSchedule = {
      ...this.props.eventSchedule.data,
      eventSettingState,
      updateSettings: true,
    };
    await this.props.actions.submitRsvpEvent(eventSchedule);

    this.handleUpdateResponse();
  };

  onCancelSchedule = async (updateSettings = false) => {
    const {
      eventSchedule: { data },
      actions: { submitRsvpEvent },
    } = this.props;
    this.setState({
      showCancelScheduleConfirmationModal: false,
      submitActionType: this.actionTypes.cancel,
    });

    const eventScheduleForCancellation = {
      ...data,
      eventState: "Canceled",
      eventSettingState: updateSettings
        ? this.settingState.canceled
        : data.state,
      updateSettings,
    };
    await submitRsvpEvent(eventScheduleForCancellation);

    this.handleUpdateResponse();
  };

  onCancelSettingsChanges = () => {
    this.setState(this.state.initialState, this.props.pageRoute.history.goBack);
  };

  onSubmitSettings = async () => {
    this.setState({
      errorMessage: "",
      submitActionType: this.actionTypes.submit,
      submitAttempted: true,
    });

    const isValid = this.validateSettings();
    if (!isValid) {
      this.setState({
        errorMessage: this.incompleteErrorMessage,
      });
      return;
    }

    const {
      isNew,
      pageRoute: {
        query: { cloneEventScheduleId },
      },
    } = this.props;
    const {
      initialState: { settings: initialSettings },
      settings,
      originalScheduleType,
      bypassUpdateRecurringSettingsPrompt,
      bypassUnlimitedCapacityPrompt,
      bypassCloneRecurringWarning,
    } = this.state;

    const validateCapacity =
      (!settings.maxCapacity || settings.maxCapacity === "0") &&
      (isNew || initialSettings.maxCapacity);
    if (validateCapacity) {
      this.setState({
        showUnlimitedCapacityConfirmationModal: !bypassUnlimitedCapacityPrompt,
      });

      if (!bypassUnlimitedCapacityPrompt) {
        return;
      }
    }

    //when updating a recurring event, toggle UpdateRecurringSettingsModal prompt
    if (!isNew && initialSettings.scheduleType === "Recurring") {
      this.setState({
        showUpdateRecurringSettingsModal: !bypassUpdateRecurringSettingsPrompt,
      });

      if (!bypassUpdateRecurringSettingsPrompt) {
        return;
      }
    }

    if (
      cloneEventScheduleId &&
      originalScheduleType === "Recurring" &&
      settings.scheduleType === "Recurring"
    ) {
      this.setState({
        showCloneRecurringWarningModal: !bypassCloneRecurringWarning,
      });
      if (!bypassCloneRecurringWarning) {
        return;
      }
    }

    if (
      settings.registrationQuestions &&
      settings.registrationQuestions.some(
        (q) => q.isActive && (!q.questionText || !q.responseType),
      )
    ) {
      return;
    }

    const eventSettingsForSubmission = _cloneDeep(settings);
    removeEmptyFromObj(eventSettingsForSubmission);
    eventSettingsForSubmission.smsCodes = settings.smsCodes?.map((c, i) => ({
      code: c,
      isPrimary: i === 0,
    }));

    if (cloneEventScheduleId) {
      eventSettingsForSubmission.eventScheduleID = null;
      eventSettingsForSubmission.eventSettingID = null;
    }

    await this.props.actions.submitRsvpEvent(eventSettingsForSubmission);

    const {
      onCreate,
      submitEvent: { error, errorMessage },
    } = this.props;

    if (error) {
      this.setState({
        errorMessage:
          errorMessage || "Something went wrong.  Please try again.",
      });
    } else {
      if (isNew) {
        this.setState({ requestedNavigation: true }, onCreate);
      } else {
        //refresh event schedules in dropdown if updated schedule name or date
        if (
          eventSettingsForSubmission.name !== initialSettings.name ||
          eventSettingsForSubmission.eventDateTime !==
            initialSettings.eventDateTime
        ) {
          this.props.actions.getRsvpEventSchedulesBasic();
        }

        //re-initialize state with event schedule settings
        this.reinitializeSettings();

        const isPast =
          eventSettingsForSubmission.occurrenceStatus !== "Upcoming" && !isNew;
        const wasImportantFieldUpdated =
          eventSettingsForSubmission.location !== initialSettings.location ||
          eventSettingsForSubmission.eventDateTime !==
            initialSettings.eventDateTime ||
          this.valueChanged(
            eventSettingsForSubmission.registrationFeeAmount,
            initialSettings.registrationFeeAmount,
          );

        if (!isPast && wasImportantFieldUpdated && this.isShliach) {
          this.setState({ showNotifyStudentsAboutEventUpdateModal: true });
        }
      }
    }
  };

  valueChanged = (originalValue, newValue) =>
    (!!originalValue || !!newValue) && originalValue !== newValue;

  validateSettings = () => {
    const {
      isNew,
      enrollment: {
        data: { isChabadHouseEnrolledInMessaging },
      },
    } = this.props;
    const {
      settings: {
        address,
        daysOfWeek,
        description,
        duration,
        eventDateTime,
        location,
        maxCapacity,
        name,
        registrationEndDateTime,
        reminderSettingType,
        registeredStudentsReminderMinutes,
        unregisteredStudentsReminderMinutes,
        registrationFeeAmount,
        scheduleType,
        shortDescription,
        smsCodes,
        tables,
        timeMinutes,
        timeHours,
        typeID,
        updateSettings,
      },
    } = this.state;

    let invalidDateTimeSettings;
    if (scheduleType === "Recurring" && (isNew || updateSettings)) {
      //when creating new recurring event or updating recurring settings, validate recurring days/time fields
      invalidDateTimeSettings =
        !daysOfWeek ||
        !daysOfWeek.length ||
        (timeMinutes === "" && timeHours === ""); //because time hours and mins can be 0 (12AM), we check for empty strings instead of falsey values
    } else {
      invalidDateTimeSettings =
        !eventDateTime || (!isNew && !registrationEndDateTime);
    }

    if (
      !description ||
      !name ||
      !duration ||
      !typeID ||
      (isChabadHouseEnrolledInMessaging &&
        (!smsCodes?.length || !shortDescription)) ||
      (location === "Other" &&
        (!address || !(address.address1 || address.locationName))) ||
      invalidDateTimeSettings ||
      !this.validateTables(tables, maxCapacity)
    ) {
      return false;
    }

    if (registrationFeeAmount === 0) {
      return false;
    }

    if (
      reminderSettingType === REMINDER_SETTING_TYPES.custom &&
      !registeredStudentsReminderMinutes &&
      !unregisteredStudentsReminderMinutes
    ) {
      this.setState({
        studentsReminderError: true,
      });
      return false;
    }

    return true;
  };

  //validate that table capacity does not exceed event capacity:
  validateTables = (tables, maxCapacity) =>
    !maxCapacity ||
    !tables.length ||
    tables
      .map((t) => parseInt(t.capacity || 0))
      .reduce((tc1, tc2) => tc1 + tc2) <= maxCapacity;

  getScheduleDatesControls = (showRecurringDaysAndTime, readOnly) => {
    const {
      sys: { customDaysOfWeek = [] },
    } = this.props;

    const {
      settings: {
        daysOfWeek,
        eventDateTime,
        registrationEndMinutes,
        timeHours,
        timeMinutes,
      },
      submitAttempted,
    } = this.state;

    return (
      <div className="mb-24">
        {showRecurringDaysAndTime ? (
          <>
            <div className="flex flex-align-center mobile-block mt-16">
              <label className="accent-text-dark">Days of the week</label>
              <div className="flex">
                {customDaysOfWeek.map((dow) => (
                  <p
                    className={`recurring-event-day mr-8${
                      daysOfWeek && daysOfWeek.indexOf(dow.enumValue) >= 0
                        ? " active"
                        : ""
                    }`}
                    key={dow.enumValue}
                    onClick={() =>
                      this.onAddRemoveRecurringDayOfWeek(dow.enumValue)
                    }
                  >
                    {dow.displayValue.substring(0, 2)}
                  </p>
                ))}
              </div>
              {submitAttempted && (!daysOfWeek || !daysOfWeek.length) && (
                <p className="error-text small-text ml-16">
                  Please select event day(s)
                </p>
              )}
            </div>
            <div className="flex flex-align-center mobile-block mt-16 mb-24">
              <label className="accent-text-dark">Time</label>
              <TimePicker
                className={`custom-input ${
                  submitAttempted && timeMinutes === "" && timeHours === ""
                    ? "error"
                    : ""
                }`}
                onChange={(_, { hours, minutes }) =>
                  this.onChangeSettings("timeHours", hours, {
                    timeMinutes: minutes,
                  })
                }
                readOnly={readOnly}
                hoursValue={timeHours}
                minutesValue={timeMinutes}
              />
            </div>
          </>
        ) : (
          <div className="flex flex-align-center mobile-block mb-24">
            <label className="accent-text-dark">Date and Time</label>
            <DateTimePicker
              className={`custom-input ${
                submitAttempted && !eventDateTime ? "error" : ""
              }`}
              name="eventDateTime"
              onChange={(nm, val) =>
                this.onChangeSettings(
                  nm,
                  val && removeTimezoneFormatFromDate(val),
                )
              }
              readOnly={readOnly}
              showTime={true}
              value={eventDateTime}
            />
          </div>
        )}

        <div className="flex flex-align-center mobile-block mb-4">
          <label className="accent-text-dark">Registration Closes</label>
          <div className="flex flex-align-center mr-8">
            <input
              className="custom-input"
              min={0}
              name="registrationEndMinutes"
              onChange={(e) =>
                this.onChangeSettings(
                  e.target.name,
                  e.target.value ? e.target.value * 60 : "",
                )
              }
              readOnly={readOnly}
              style={{ maxWidth: "72px" }}
              type="number"
              value={registrationEndMinutes ? registrationEndMinutes / 60 : ""}
            />
            <p className="accent-text ml-8">hours before</p>
          </div>
        </div>
        <span className="accent-text small-text italic-text">
          Leaving this blank will have the registration close at the event start
          time
        </span>
      </div>
    );
  };

  getCustomQuestionsSection = (canEdit) => {
    const {
      settings: {
        registrationQuestions: thisEventQuestions,
        allRegistrationQuestions,
      },
      submitAttempted,
    } = this.state;

    const splitQuestions = partition(
      allRegistrationQuestions,
      (q) => q.isEventSpecific,
    );

    const eventSpecificQuestions = splitQuestions[0];
    const allEventQuestions = splitQuestions[1];

    const additionalQuestions = thisEventQuestions
      ?.map((q, index) => ({ ...q, index }))
      ?.filter((q) => !q.id);

    const allQuestionsSection = () => (
      <div className="accent-text mt-10">
        You selected these questions to be asked on all events. This setting can
        be edited on the RSVP Settings page.
        {allEventQuestions.map((q, i) => (
          <div key={i} className="custom-question-question mt-10">
            {q.questionText}
          </div>
        ))}
      </div>
    );

    const eventSpecificQuestionsSection = () => (
      <div className="accent-text mt-24">
        <div>
          Select from previous custom questions to be asked when registering for
          this event
        </div>
        <div>
          {eventSpecificQuestions.map((q, i) => {
            const checked = thisEventQuestions.some((eq) => eq.id === q.id);
            return (
              <div key={i} className="mt-16">
                <Checkbox
                  checked={checked}
                  className="accent-text-dark event-settings-checkbox"
                  disabled={!canEdit}
                  name={q.id}
                  label={q.questionText}
                  onChange={() => {
                    let questions = _cloneDeep(
                      this.state.settings.registrationQuestions,
                    );
                    if (checked) {
                      questions = questions.filter((eq) => eq.id !== q.id);
                    } else {
                      questions = [...questions, q];
                    }
                    this.onChangeSettings("registrationQuestions", questions);
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
    );

    const addQuestionsSection = () => (
      <>
        <div className="flex flex-align-center mt-16">
          Add Custom Question
          <i
            className="material-icons link-text ml-16"
            onClick={this.onAddRemoveQuestion}
          >
            add_circle
          </i>
        </div>
        <div className="accent-text mt-8 flex flex-align-center mb-16">
          These questions will be saved in RSVP general program settings and can
          be edited there.
        </div>
        {additionalQuestions?.map((question, index) => (
          <RsvpCustomQuestion
            key={index}
            question={question}
            onChange={this.onChangeSettings}
            onChangeEvent={this.onChangeSettingsEvt}
            submitAttempted={submitAttempted}
            onAddRemoveQuestion={this.onAddRemoveQuestion}
            isEvent
          />
        ))}
      </>
    );

    if (!canEdit && !allEventQuestions?.length && !thisEventQuestions?.length) {
      return (
        <p className="accent-text mt-16">
          There are no custom questions for this event
        </p>
      );
    }

    return (
      <>
        {allEventQuestions?.length > 0 && allQuestionsSection()}
        {eventSpecificQuestions?.length > 0 && eventSpecificQuestionsSection()}
        {canEdit && addQuestionsSection()}
      </>
    );
  };

  render() {
    const {
      actions,
      chabadHouseTags: {
        data: chabadHouseTagOptions = [],
        error: chabadHouseTagsError,
      } = {},
      enrollment: {
        data: {
          chabadHouseMessagingNumber,
          chabadHouseRsvpURL,
          chabadHouseHasPaymentProviders,
          isChabadHouseEnrolledInMessaging,
          forChabadHouseID,
          programID,
          programScheduleID,
          globalDefaultHasReminders,
          globalDefaultRegisteredStudentsReminderMinutes,
          globalDefaultUnregisteredStudentsReminderMinutes,
        } = {},
      },
      eventSchedule: { data: { hasLinkedSubmittedInteraction } = {} },
      pageRoute: {
        query: { cloneEventScheduleId },
      },
      isNew,
      refreshRsvpItem,
      submitEvent: { loading },
      createInteractionsResult,
      sys: {
        customDaysOfWeek = [],
        rsvpEventLocations = [],
        interactionDurations = [],
        interactionTypes = [],
      },
    } = this.props;

    const {
      settings,
      errorMessage,
      initialState: { settings: initialSettings },
      requestedNavigation,
      showCancelScheduleConfirmationModal,
      showCancelRecurringSettingsModal,
      showUnlimitedCapacityConfirmationModal,
      showUpdateRecurringSettingsModal,
      showNotifyStudentsAboutEventUpdateModal,
      showCloneRecurringWarningModal,
      submitActionType,
      submitAttempted,
      showResumeModal,
      showPauseModal,
      studentsReminderError,
      overrideDefaultConfirmationMessage,
    } = this.state;

    const {
      accessTags,
      address,
      canEditIsIsraelRelated,
      customConfirmationMessage,
      description,
      duration,
      eventSettingState,
      registrationFeeAmount,
      eventScheduleID,
      eventState,
      imageURL,
      isIsraelRelated,
      isPrivate,
      location,
      maxCapacity,
      name,
      numberOfRegisteredPeople,
      numberOfPendingPeople,
      occurrenceStatus,
      askForDonation,
      scheduleType,
      shortDescription,
      smsCodes,
      tables,
      typeID,
      reminderSettingType,
      registeredStudentsReminderMinutes,
      unregisteredStudentsReminderMinutes,
      wasRegisteredStudentsReminderAlreadyTriggered,
      wasUnregisteredStudentsReminderAlreadyTriggered,
    } = settings;

    const { durationOptions, typeOptions } = getFieldOptionsForProgramType(
      "Rsvp",
      interactionDurations,
      interactionTypes,
    );

    const hasChanges = !_isEqual(settings, initialSettings);

    // different fields are editable based on the state of the event
    const isCanceled = eventState === "Canceled";
    const isPast = occurrenceStatus !== "Upcoming" && !isNew;
    const canEditPayment =
      !isCanceled && !isPast && chabadHouseHasPaymentProviders;
    const canEditTables = !isCanceled && !isPast;
    const canEditCustomQuestions = !isCanceled && !isPast;
    const canEditPrivateEventSettings = !isCanceled && !isPast;

    const hasTablesError =
      submitAttempted && !this.validateTables(tables, maxCapacity);

    const submittedForGrantText = this.isShliach
      ? "was submitted for a grant"
      : "is locked";

    const otherLocationError =
      location === "Other" &&
      submitAttempted &&
      !(address.address1 || address.locationName);

    return (
      <>
        <Prompt
          when={!requestedNavigation && hasChanges}
          message={`Your ${
            isNew
              ? "settings have not been submitted"
              : "changes have not been saved"
          }. Are you sure you want to leave this page?`}
        />
        <div className="mobile-flat-card rsvp-event-settings-form">
          {cloneEventScheduleId ? (
            <div className="clone-event-warning">
              <div className="flex">
                <i
                  className="material-icons no-hover medium-text"
                  style={{ color: "#0D324E" }}
                >
                  info_outlined
                </i>
                <div>
                  <span className="fw-900" style={{ color: "#2774AE" }}>
                    Cloned Event
                  </span>
                  <div className="mt-8" style={{ lineHeight: 1.25 }}>
                    This event was cloned. Not always will all event fields be
                    copied over from the original event. Please review all
                    fields to make sure all settings are correct.
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex flex-justify-space mobile-block">
              <div className="xl-text fw-700 line-height-double flex flex-align-center mb-0 program-form-section-title">
                {isNew ? "Add New Event" : "Settings"}
                {isCanceled && (
                  <span className="small-text accent-text fw-500 ml-16">
                    * Canceled event schedules are read-only
                  </span>
                )}
                {isPast && (
                  <span className="small-text accent-text fw-500 ml-16">
                    * Only limited changes are allowed for past event schedules
                  </span>
                )}
              </div>

              {!isNew && this.canManageActivity && (
                <EventInteractionButton
                  eventData={settings}
                  eventHasChanges={hasChanges}
                  createInteractionsAction={
                    actions.createEventInteractionsForChabadHouse
                  }
                  createInteractionsResult={createInteractionsResult}
                  refreshEvent={refreshRsvpItem}
                  reinitializeSettings={this.reinitializeSettings}
                  rsvpProgramScheduleId={programScheduleID}
                />
              )}
            </div>
          )}
          <div
            className={`${
              cloneEventScheduleId ? "mt-24" : "program-form-section"
            }`}
          >
            <p className="medium-text fw-700 mb-16">
              Event Name and Description
            </p>
            <div className="program-form-section-short-inputs">
              <div className="flex flex-align-center mb-8 mobile-block">
                <label className="mr-24 accent-text-dark">Event Name</label>
                <input
                  className={`custom-input ${
                    submitAttempted && !name ? "error" : ""
                  }`}
                  name="name"
                  onChange={this.onChangeSettingsEvt}
                  readOnly={isCanceled || isPast}
                  type="text"
                  value={name}
                />
              </div>
              {isChabadHouseEnrolledInMessaging && (
                <div
                  className="flex flex-align-center mb-8 mobile-block"
                  style={{ width: "calc(100% - 40px)" }}
                >
                  <label className="mr-24 accent-text-dark flex flex-align-center mobile-flex">
                    <span className="no-wrap">Abbreviated Name</span>
                    <span className="tooltip-container flex ml-8 mobile-full-width">
                      <i className="material-icons link-text no-hover medium-text">
                        info
                      </i>
                      <span className="tooltip">
                        You can create multiple codes for this event. Simply
                        press enter or tab after entering each code to add
                        another. The first code will be the default sent out in
                        all SMS messages. The others can be a backup in case of
                        a typo, etc.
                      </span>
                    </span>
                  </label>
                  <TagInput
                    className={`mobile-height-auto rsvp-tag-container ${
                      submitAttempted && !smsCodes?.length ? "error" : ""
                    }`}
                    containerStyle={{
                      minHeight: 46,
                    }}
                    name="smsCodes"
                    tags={smsCodes || []}
                    onChange={(name, tags) => {
                      const upperCase = tags.map((t) => t.toUpperCase());
                      this.onChangeSettings(name, upperCase);
                    }}
                    disabled={isCanceled || isPast}
                  />
                </div>
              )}
            </div>
            <div className="mb-8">
              <label className="mr-24 accent-text-dark flex flex-align-center mobile-flex">
                <span className="no-wrap">Event Description</span>
                <span className="tooltip-container flex ml-8 mobile-full-width">
                  <i className="material-icons link-text no-hover medium-text">
                    info
                  </i>
                  <span className="tooltip">
                    Full description will appear on the website and in the email
                    confirmation that goes out to students
                  </span>
                </span>
              </label>
              <textarea
                className={`custom-input custom-message mt-8 ${
                  submitAttempted && !description ? "error" : ""
                }`}
                name="description"
                onChange={this.onChangeSettingsEvt}
                placeholder="Enter description here..."
                readOnly={isCanceled || isPast}
                style={{ marginLeft: 0 }}
                value={description}
              />
            </div>
            {isChabadHouseEnrolledInMessaging && (
              <div className="mb-8 relative">
                <label className="mr-24 accent-text-dark flex flex-align-center mobile-flex">
                  <span className="no-wrap">Short Description</span>
                  <span className="tooltip-container flex ml-8 mobile-full-width">
                    <i className="material-icons link-text no-hover medium-text">
                      info
                    </i>
                    <span className="tooltip">
                      100 character max description for use with SMS messaging
                    </span>
                  </span>
                </label>
                <textarea
                  className={`custom-input custom-message mt-8 ${
                    submitAttempted && !shortDescription ? "error" : ""
                  }`}
                  maxLength={100}
                  name="shortDescription"
                  onChange={this.onChangeSettingsEvt}
                  placeholder="Enter short description here..."
                  readOnly={isCanceled || isPast}
                  style={{ marginLeft: 0, height: "60px" }}
                  value={shortDescription}
                />
                <p className="total-characters" style={{ right: "46px" }}>
                  {shortDescription?.length || 0} / 100
                </p>
              </div>
            )}
            <div className="flex flex-align-center mb-8 mobile-block program-form-section-short-inputs">
              <label className="mr-24 accent-text-dark flex flex-align-center mobile-flex">
                <span className="no-wrap">Event Type</span>
                {this.isShliach && (
                  <span className="tooltip-container flex ml-8 mobile-full-width">
                    <i className="material-icons link-text no-hover medium-text">
                      info
                    </i>
                    <span className="tooltip">
                      Type will be used for student interaction tracking
                      purposes, and will not be displayed to the student on the
                      public site
                    </span>
                  </span>
                )}
              </label>
              <div className="tooltip-container">
                <Select
                  className={`custom-select ${
                    submitAttempted && !typeID ? "error" : ""
                  }`}
                  classNamePrefix="custom-select"
                  isDisabled={isCanceled || hasLinkedSubmittedInteraction}
                  isSearchable={true}
                  onChange={({ value }) =>
                    this.onChangeSettings("typeID", value)
                  }
                  options={typeOptions}
                  value={
                    typeID &&
                    flattenArray(
                      typeOptions.map(({ options }) => options),
                    ).find(
                      ({ value }) =>
                        value && value.toString() === typeID.toString(),
                    )
                  }
                />
                {hasLinkedSubmittedInteraction && (
                  <span className="tooltip">
                    Type cannot be edited because this event is linked to an
                    interaction that {submittedForGrantText}
                  </span>
                )}
              </div>
            </div>
            <div className="flex flex-align-center mb-8 mobile-block program-form-section-short-inputs">
              <label className="mr-24 accent-text-dark flex flex-align-center mobile-flex">
                <span className="no-wrap">Event Duration</span>
                {this.isShliach && (
                  <span className="tooltip-container flex ml-8 mobile-full-width">
                    <i className="material-icons link-text no-hover medium-text">
                      info
                    </i>
                    <span className="tooltip">
                      Duration will be used for student interaction tracking
                      purposes, and will not be displayed to the student on the
                      public site
                    </span>
                  </span>
                )}
              </label>
              <div className="tooltip-container">
                <Select
                  className={`custom-select ${
                    submitAttempted && !duration ? "error" : ""
                  }`}
                  classNamePrefix="custom-select"
                  isDisabled={isCanceled}
                  onChange={({ value }) =>
                    this.onChangeSettings("duration", value)
                  }
                  options={durationOptions}
                  value={
                    duration &&
                    durationOptions.find(
                      ({ value }) => value.toString() === duration.toString(),
                    )
                  }
                />
                {hasLinkedSubmittedInteraction && (
                  <span className="tooltip">
                    Duration cannot be made shorter because this event is linked
                    to an interaction that {submittedForGrantText}
                  </span>
                )}
              </div>
            </div>
            <div className="flex flex-align-center mb-8 mobile-block program-form-section-short-inputs">
              <label className="mr-24 accent-text-dark flex flex-align-center mobile-flex">
                <span>Registration fee (leave blank for a free event)</span>
                {!chabadHouseHasPaymentProviders && this.isShliach && (
                  <span className="tooltip-container flex ml-8 mobile-full-width">
                    <i className="material-icons link-text no-hover medium-text">
                      info
                    </i>
                    <span className="tooltip">
                      In order to set a registration fee, complete the Stripe
                      account section in your Chabad House profile{" "}
                      <span
                        style={{
                          textDecoration: "underline",
                          color: "blue",
                          cursor: "pointer",
                        }}
                        onClick={this.goToChabadHouseActivitySettings}
                      >
                        here
                      </span>
                    </span>
                  </span>
                )}
              </label>
              <NumberFormat
                allowNegative={false}
                className={`custom-input dollar-input 
                  ${
                    submitAttempted && registrationFeeAmount === 0
                      ? "error"
                      : ""
                  }
                `}
                onValueChange={({ floatValue }) => {
                  this.onChangeSettings("registrationFeeAmount", floatValue);
                }}
                decimalScale={2}
                thousandSeparator={true}
                value={registrationFeeAmount}
                readOnly={!canEditPayment}
              />
              {submitAttempted && registrationFeeAmount === 0 && (
                <span className="small-text error-text ml-4">
                  Registration fee must be greater than 0
                </span>
              )}
            </div>

            {/* TODO why don't the checkboxes accept focus from tab? */}
            <div className="flex flex-align-center mt-16 mb-8 mobile-block program-form-section-short-inputs">
              <Checkbox
                checked={askForDonation}
                className="accent-text-dark event-settings-checkbox"
                disabled={!canEditPayment}
                label="Request donation when people are registering for this event"
                name="askForDonation"
                onChange={this.onChangeSettings}
              />
              {!chabadHouseHasPaymentProviders && this.isShliach && (
                <span
                  className="tooltip-container flex ml-8 mobile-full-width"
                  style={{ bottom: 4 }}
                >
                  <i className="material-icons link-text no-hover medium-text">
                    info
                  </i>
                  <span className="tooltip">
                    In order to ask for a donation, complete the Stripe account
                    section in your Chabad House profile{" "}
                    <span
                      style={{
                        textDecoration: "underline",
                        color: "blue",
                        cursor: "pointer",
                      }}
                      onClick={this.goToChabadHouseActivitySettings}
                    >
                      here
                    </span>
                  </span>
                </span>
              )}
            </div>
            <div className="flex flex-align-center mt-16 mb-8 program-form-section-short-inputs">
              <div className="tooltip-container">
                <Checkbox
                  checked={isIsraelRelated}
                  className="accent-text-dark event-settings-checkbox"
                  disabled={!isNew && !canEditIsIsraelRelated}
                  label="Israel/Antisemitism Related"
                  name="isIsraelRelated"
                  onChange={this.onChangeSettings}
                />
                {hasLinkedSubmittedInteraction && !canEditIsIsraelRelated && (
                  <span className="tooltip">
                    Israel/Antisemitism Related cannot be deselected because
                    this event is linked to an interaction that{" "}
                    {submittedForGrantText}
                  </span>
                )}
              </div>
              {this.isShliach && (
                <span
                  className="tooltip-container flex ml-8"
                  style={{ bottom: 4 }}
                >
                  <i className="material-icons link-text no-hover medium-text">
                    info
                  </i>
                  <span className="tooltip">
                    Israel/Antisemitism Related will be used for student
                    interaction tracking purposes, and will not be displayed to
                    the student on the public site{" "}
                  </span>
                </span>
              )}
            </div>
          </div>
          <div className="program-form-section">
            <p className="medium-text fw-700 mb-8">Event Image </p>
            <div className="accent-text-dark mb-24 line-height-double">
              <p>This image will be displayed on the event card</p>
              <p>
                For generic event images click{" "}
                <a
                  className="link-text"
                  href={`/programs/${programID}?sched=${programScheduleID}#generic-images`}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  here
                </a>
              </p>
              <p>
                Note that images with text may look disproportionate on
                different screen sizes
              </p>
            </div>
            <ImageUpload
              cropAspectHeight={1}
              cropAspectWidth={1.5}
              disabled={isCanceled || isPast}
              forceCrop={true}
              imageType="event_banner"
              imageURL={imageURL}
              onChange={this.onChangeSettings}
              label="Upload Image"
              name="imageURL"
              uploadedLabel="Change Image"
            />
          </div>
          <div className="program-form-section">
            <p className="medium-text fw-700 mb-16">Event Location</p>
            <div style={{ maxWidth: "768px" }}>
              <div className="flex flex-align-center mobile-block mb-8">
                <label className="accent-text-dark line-height-double">
                  Where will this event be taking place?
                </label>
                <Toggle
                  className="event-location-toggle"
                  disabled={isCanceled}
                  name="location"
                  onChange={this.onChangeSettings} // note: per ctevel we are maintaining 'Other' location address details even on toggle to other location types
                  options={
                    rsvpEventLocations &&
                    rsvpEventLocations
                      .sort((el1, el2) =>
                        el1.displayValue.localeCompare(el2.displayValue),
                      )
                      .map((el) => ({
                        value: el.enumValue,
                        display: el.displayValue,
                      }))
                  }
                  value={location}
                />
              </div>
              {location === "Other" && address && (
                <>
                  {otherLocationError && (
                    <div className="flex">
                      <div style={{ width: 184 }}></div>
                      <div className="error-text mb-8">
                        Name or Street address is required
                      </div>
                    </div>
                  )}
                  <div className="flex flex-align-center mobile-block mb-8">
                    <label className="accent-text-dark">Name</label>
                    <input
                      className={`custom-input full-width ${
                        otherLocationError && "error"
                      }`}
                      disabled={location !== "Other"}
                      name="address.locationName"
                      onChange={this.onChangeSettingsEvt}
                      readOnly={isCanceled}
                      type="text"
                      value={address.locationName}
                    />
                  </div>
                  <div className="flex mobile-block">
                    <label className="accent-text-dark address-label">
                      Address
                    </label>
                    <div className="full-width">
                      <input
                        className={`custom-input full-width mb-8 ${
                          otherLocationError && "error"
                        }`}
                        disabled={location !== "Other"}
                        name="address.address1"
                        onChange={this.onChangeSettingsEvt}
                        placeholder="Street Address"
                        readOnly={isCanceled}
                        type="text"
                        value={address.address1}
                      />

                      <div className="flex mobile-block">
                        <input
                          className="custom-input flex full-width mb-8"
                          disabled={location !== "Other"}
                          name="address.address2"
                          onChange={this.onChangeSettingsEvt}
                          placeholder="Apt #, Suite, Floor"
                          readOnly={isCanceled}
                          type="text"
                          value={address.address2}
                        />
                        <input
                          className="custom-input full-width mr-8 ml-8 mobile-ml-0 mobile-mb-8"
                          disabled={location !== "Other"}
                          name="address.city"
                          onChange={this.onChangeSettingsEvt}
                          placeholder="City"
                          readOnly={isCanceled}
                          type="text"
                          value={address.city}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="program-form-section">
            <div>
              <p className="medium-text fw-700 mb-16">Schedule Setup</p>
              <div className="flex flex-align-center mobile-block mb-24">
                <label className="accent-text-dark">Schedule Type</label>
                <Toggle
                  disabled={!isNew} //schedule type cannot be updated for existing events
                  name="scheduleType"
                  onChange={(nm, val) =>
                    this.onChangeSettings(nm, val, {
                      daysOfWeek: "",
                      eventDateTime: "",
                      timeMinutes: "",
                      timeHours: "",
                    })
                  }
                  options={[
                    {
                      value: "OneTime",
                      display: "One Time",
                    },
                    {
                      value: "Recurring",
                      display: "Recurring",
                    },
                  ]}
                  value={scheduleType}
                />
                {!isNew && (
                  <div className="tooltip-container ml-8">
                    <i className="material-icons accent-text large-text">
                      info
                    </i>
                    <span className="tooltip">
                      Event type cannot be updated after event creation
                    </span>
                  </div>
                )}
              </div>
            </div>
            {this.getScheduleDatesControls(
              isNew && scheduleType === "Recurring", //recurring days of week & time controls for new recurring settings
              isCanceled || isPast,
            )}
          </div>
          <ReminderSettings
            registeredStudentsReminderMinutes={
              registeredStudentsReminderMinutes
            }
            unregisteredStudentsReminderMinutes={
              unregisteredStudentsReminderMinutes
            }
            reminderSettingType={reminderSettingType}
            onChange={this.onChangeSettings}
            globalDefaultHasReminders={globalDefaultHasReminders}
            globalDefaultRegisteredStudentsReminderMinutes={
              globalDefaultRegisteredStudentsReminderMinutes
            }
            globalDefaultUnregisteredStudentsReminderMinutes={
              globalDefaultUnregisteredStudentsReminderMinutes
            }
            error={studentsReminderError}
            setError={(value) => {
              this.setState({ studentsReminderError: value });
            }}
            readOnly={isCanceled || isPast}
            wasRegisteredStudentsReminderAlreadyTriggered={
              wasRegisteredStudentsReminderAlreadyTriggered
            }
            wasUnregisteredStudentsReminderAlreadyTriggered={
              wasUnregisteredStudentsReminderAlreadyTriggered
            }
            setReminderSettingType={async (value) => {
              if (
                value === REMINDER_SETTING_TYPES.global ||
                value === REMINDER_SETTING_TYPES.none
              ) {
                await this.setState({
                  settings: {
                    ...settings,
                    reminderSettingType: value,
                    registeredStudentsReminderMinutes: null,
                    unregisteredStudentsReminderMinutes: null,
                  },
                });
              } else {
                await this.onChangeSettings(
                  "reminderSettingType",
                  REMINDER_SETTING_TYPES.custom,
                );
              }
            }}
          />
          <div className="program-form-section">
            <div className="mb-16">
              <p className="medium-text fw-700 mb-8">Event Permissions</p>
              <div className="accent-text-dark line-height-double">
                Choose who can register for this event. Events are hidden from
                students who are restricted from registering.
              </div>
            </div>
            <div>
              <RadioGroup
                name="isPrivate"
                value={isPrivate}
                onChange={(_, val) => {
                  this.onChangeSettings("isPrivate", val === "true", {
                    accessTags: [],
                  });
                }}
              >
                <FormControlLabel
                  value={false}
                  control={
                    <Radio
                      color="primary"
                      disabled={!canEditPrivateEventSettings}
                    />
                  }
                  label={
                    <Typography
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      Public
                      <Tooltip
                        title="Registration is open to all students"
                        style={{ marginLeft: "8px" }}
                      >
                        <i className="material-icons accent-text large-text">
                          info
                        </i>
                      </Tooltip>
                    </Typography>
                  }
                />
                <FormControlLabel
                  value={true}
                  control={
                    <Radio
                      color="primary"
                      disabled={!canEditPrivateEventSettings}
                    />
                  }
                  label={
                    <Typography
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      Private
                      <Tooltip
                        title="Registration is open to selected groups and to anyone with the event link or SMS code"
                        style={{ marginLeft: "8px" }}
                      >
                        <i className="material-icons accent-text large-text">
                          info
                        </i>
                      </Tooltip>
                    </Typography>
                  }
                />
              </RadioGroup>
              {isPrivate && (
                <>
                  <MultiSelect
                    className="mt-16"
                    isDisabled={!canEditPrivateEventSettings}
                    isSearchable={true}
                    isMulti={true}
                    name="accessTags"
                    onChange={(name, vals) => {
                      const tagIds = vals.map((v) => v.value);
                      this.onChangeSettings(
                        name,
                        chabadHouseTagOptions.filter((t) =>
                          tagIds.includes(t.id),
                        ),
                      );
                    }}
                    options={chabadHouseTagOptions.map((t) => ({
                      label: t.tagName,
                      value: t.id,
                    }))}
                    placeholder="Select group(s) with access"
                    value={accessTags.map((t) => ({
                      label: t.tagName,
                      value: t.id,
                    }))}
                  />
                  {chabadHouseTagsError && (
                    <p className="error-text mt-8">
                      Error retrieving student group options for access
                    </p>
                  )}
                  <div className="rsvp-private-access-note">
                    <i className="material-icons xl-text">info_outlined</i>
                    <div>
                      <Typography
                        color="primary"
                        variant="subtitle1"
                        style={{ lineHeight: 1.25, marginBottom: "6px" }}
                      >
                        How can students register for this event?
                      </Typography>
                      <Typography variant="body2">
                        1.{" "}
                        <Typography component="span" variant="subtitle2">
                          Events page
                        </Typography>{" "}
                        - Only members of the selected groups above will see
                        this event on their events page.
                        <br />
                        2.{" "}
                        <Typography component="span" variant="subtitle2">
                          Shareable URL
                        </Typography>{" "}
                        - All students can register via the shareable URL.
                        <br />
                        {isChabadHouseEnrolledInMessaging &&
                          !!chabadHouseMessagingNumber && (
                            <>
                              3.{" "}
                              <Typography component="span" variant="subtitle2">
                                SMS message
                              </Typography>{" "}
                              - All students can register by texting the event
                              name/abbreviated name to{" "}
                              {chabadHouseMessagingNumber}.
                            </>
                          )}
                      </Typography>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="program-form-section">
            <div className="mb-24">
              <p className="medium-text fw-700 mb-16">
                Event Capacity (optional)
              </p>
              <div className="flex flex-align-center mobile-block">
                <label className="accent-text-dark">Total Capacity</label>
                <input
                  className="custom-input"
                  min={0}
                  name="maxCapacity"
                  onChange={this.onChangeSettingsEvt}
                  readOnly={isCanceled}
                  type="number"
                  value={maxCapacity}
                />
              </div>
            </div>
            <div>
              <div className="flex flex-align-center mb-16">
                <p className="medium-text fw-700">Tables (optional)</p>
                {canEditTables && (
                  <i
                    className={`material-icons link-text ml-16 ${
                      hasTablesError ? "disabled" : ""
                    }`}
                    onClick={
                      hasTablesError ? null : () => this.onAddRemoveTable()
                    }
                  >
                    add_circle
                  </i>
                )}
                {hasTablesError && (
                  <p className="error-text ml-16">
                    Your table capacity exceeds the max capacity for this event
                  </p>
                )}
              </div>
              {!tables?.length ? (
                !canEditTables ? (
                  <p className="accent-text">
                    There are no tables for this event
                  </p>
                ) : (
                  <p className="accent-text flex flex-align-center">
                    Click the{" "}
                    <i className="material-icons medium-text ml-4 mr-4">
                      add_circle
                    </i>{" "}
                    above to add a table
                  </p>
                )
              ) : (
                <div className="two-column-grid mobile-one-column-grid event-capacity-tables-grid">
                  {tables.map((table, tableIndex) => (
                    <div className="flex flex-align-center" key={tableIndex}>
                      <div
                        className="full-width"
                        style={{
                          border: "1px solid #edecec",
                          borderRadius: 3,
                          padding: 16,
                        }}
                      >
                        <p className="fw-700 mr-32 mb-8">
                          Table {table.sortOrder}
                        </p>
                        <div className="flex flex-align-center mobile-block mb-4">
                          <p className="accent-text-dark mr-8 mobile-mr-0 mobile-mb-4">
                            Table Name
                          </p>
                          <input
                            className="custom-input"
                            name={`tables[${tableIndex}].name`}
                            onChange={this.onChangeSettingsEvt}
                            readOnly={!canEditTables}
                            value={table.name}
                          />
                        </div>
                        <div className="flex flex-align-center mobile-block">
                          <p className="accent-text-dark mr-8 mobile-mr-0 mobile-mb-4">
                            Amount of seats
                          </p>
                          <input
                            className="custom-input"
                            min={0}
                            name={`tables[${tableIndex}].capacity`}
                            onChange={this.onChangeSettingsEvt}
                            readOnly={!canEditTables}
                            style={{ maxWidth: 64 }}
                            type="number"
                            value={table.capacity}
                          />
                        </div>
                      </div>
                      {canEditTables && (
                        <i
                          className="material-icons link-text ml-16"
                          onClick={() => this.onAddRemoveTable(table.sortOrder)}
                        >
                          delete
                        </i>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
            <ReservationConfirmationMessageSettings
              overrideDefaultConfirmationMessage={
                overrideDefaultConfirmationMessage
              }
              name={name}
              rsvpItemType="event"
              customConfirmationMessage={customConfirmationMessage}
              onChangeState={this.onChangeState}
              onChangeSettingsEvt={this.onChangeSettingsEvt}
              onChangeSettings={this.onChangeSettings}
            />
            <div className="program-form-section">
              <p className="medium-text fw-700">Custom Questions</p>
              {this.getCustomQuestionsSection(canEditCustomQuestions)}
            </div>
          </div>
          {!isNew && eventScheduleID && (
            <div className="program-form-section">
              <p className="medium-text fw-700 mb-8">Shareable URLs</p>
              <div>
                <label className="accent-text-dark mr-24 tablet-mt-8 mobile-mt-8 line-height-double">
                  This event's shareable URL
                </label>
                <p className="small-text accent-text mb-8">
                  Page will display this event only
                </p>
                <CopyToClipboardTextbox
                  className="copy-to-clipboard-textbox flex flex-justify-space"
                  goToLabel="View Link"
                  id="rsvp-sched-url"
                  label="Copy Link"
                  showGoTo={true}
                  text={`${chabadHouseRsvpURL}/events/${eventScheduleID}`}
                />
              </div>
              <div className="mt-16">
                <label className="accent-text-dark mr-24 tablet-mt-8 mobile-mt-8 line-height-double">
                  Your Chabad House's RSVP System shareable URL
                </label>
                <p className="small-text accent-text mb-8">
                  Page will display all your Chabad House events and resources
                </p>
                <div className="full-width">
                  <CopyToClipboardTextbox
                    className="copy-to-clipboard-textbox flex flex-justify-space"
                    goToLabel="View Link"
                    id="rsvp-events-url"
                    label="Copy Link"
                    showGoTo={true}
                    text={chabadHouseRsvpURL}
                  />
                  {!!forChabadHouseID && this.isShliach && (
                    <p className="small-text accent-text-dark mt-8 line-height-double">
                      The URL Friendly Name (the part of the URL that references
                      your Chabad House) can be updated in your Chabad House
                      profile by clicking{" "}
                      <a
                        className="link-text"
                        href={`/account`} //TODO scroll to chabad house section
                      >
                        here
                      </a>
                    </p>
                  )}
                </div>
              </div>
            </div>
          )}

          {!isNew && (
            <>
              <div className="program-form-section cancel-schedule-section">
                {!isCanceled && !isPast && (
                  <Button
                    variant="contained"
                    style={{ backgroundColor: "#FF6B6B" }}
                    disabled={
                      numberOfRegisteredPeople > 0 ||
                      numberOfPendingPeople > 0 ||
                      loading
                    }
                    onClick={() =>
                      this.setState({
                        [scheduleType === "Recurring"
                          ? "showCancelRecurringSettingsModal"
                          : "showCancelScheduleConfirmationModal"]: true,
                      })
                    }
                  >
                    {submitActionType === this.actionTypes.cancel && loading
                      ? "Canceling..."
                      : "Cancel Schedule"}
                  </Button>
                )}
                {scheduleType === "Recurring" && (
                  <Tooltip
                    title={
                      hasChanges
                        ? "Please save changes before pausing/resuming schedule"
                        : ""
                    }
                  >
                    <span>
                      {eventSettingState === this.settingState.paused ? (
                        <Button
                          className="ml-16"
                          variant="contained"
                          style={{ backgroundColor: "#4FCEC5" }}
                          disabled={loading || hasChanges}
                          onClick={() =>
                            this.setState({ showResumeModal: true })
                          }
                        >
                          Resume Schedule
                        </Button>
                      ) : (
                        <>
                          {!isCanceled && !isPast && (
                            <Button
                              style={{
                                marginLeft: 16,
                                backgroundColor: "#FF9E57",
                              }}
                              variant="contained"
                              disabled={
                                numberOfRegisteredPeople > 0 ||
                                numberOfPendingPeople > 0 ||
                                loading ||
                                hasChanges
                              }
                              onClick={() =>
                                this.setState({ showPauseModal: true })
                              }
                            >
                              Pause Schedule
                            </Button>
                          )}
                        </>
                      )}
                    </span>
                  </Tooltip>
                )}
              </div>
              {numberOfRegisteredPeople > 0 || numberOfPendingPeople > 0 ? (
                <Typography variant="body2" className="mt-8 small-text">
                  An event schedule with registrations cannot be cancelled
                  {scheduleType === "Recurring" && " or paused"}.
                </Typography>
              ) : (
                (submitActionType === this.actionTypes.cancel ||
                  isCanceled ||
                  isPast) &&
                !loading &&
                errorMessage && (
                  <p className="error-text small-text mt-8">{errorMessage}</p>
                )
              )}
            </>
          )}
        </div>

        {!isCanceled && (
          <div className="flex flex-justify-start program-form-btns mt-40">
            <Button
              variant="contained"
              disabled={loading || !hasChanges}
              onClick={this.onCancelSettingsChanges}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              className="ml-24"
              disabled={loading || !hasChanges}
              onClick={() => {
                this.onSubmitSettings();
              }}
            >
              {submitActionType === this.actionTypes.submit && loading
                ? "Saving..."
                : isNew
                ? "Schedule"
                : "Save"}
            </Button>
          </div>
        )}

        {submitAttempted &&
          submitActionType === this.actionTypes.submit &&
          !loading &&
          errorMessage && (
            <div className="error-message mt-16">{errorMessage}</div>
          )}

        {/* Unlimited Capacity Confirmation */}
        <ConfirmationModal
          cancel={() =>
            this.setState({ showUnlimitedCapacityConfirmationModal: false })
          }
          confirm={async () => {
            this.setState({ bypassUnlimitedCapacityPrompt: true }, () =>
              this.onSubmitSettings(),
            );
          }}
          message={`${
            maxCapacity === "0"
              ? "Leaving event capacity at 0 means you have set no capacity and"
              : "Not setting event capacity means that"
          } this is an unlimited event. Are you sure you would like this event to have an unlimited capacity?`}
          show={showUnlimitedCapacityConfirmationModal}
          title="Unlimited Capacity Event Confirmation"
        />

        {showResumeModal && (
          <ConfirmationModal
            cancel={() => this.setState({ showResumeModal: false })}
            confirm={this.handleConfirmResume}
            message={
              "Are you sure you want to resume this event? Clicking yes will create your next upcoming event. Please note that this can take up to 15 minutes to display"
            }
            show={showResumeModal}
            title="Resume Event"
          />
        )}

        {showPauseModal && (
          <ConfirmationModal
            cancel={() => this.setState({ showPauseModal: false })}
            confirm={this.handleConfirmPause}
            message={
              "Pausing this event will cancel the upcoming scheduled event. " +
              "Are you sure you want to pause this event?"
            }
            show={showPauseModal}
            title="Pause Event"
          />
        )}

        {/* Update Recurring Schedule Settings Prompt */}
        {showUpdateRecurringSettingsModal &&
          (isPast ? (
            <ConfirmationModal
              cancel={() =>
                this.setState(
                  {
                    showUpdateRecurringSettingsModal: false,
                    bypassUpdateRecurringSettingsPrompt: true,
                  },
                  () => this.onSubmitSettings(),
                )
              }
              cancelText="OK"
              message={
                "Only this event schedule will be updated. " +
                "To update future occurrences of this event, update the current event schedule."
              }
              noConfirm
              show={true}
              title="Update recurring event"
            />
          ) : (
            <UpdateRecurringSettingsModal
              customDaysOfWeek={customDaysOfWeek}
              errorMessage={errorMessage}
              initialSettings={initialSettings}
              onApplyRecurringDateChanges={(recurringDateSettings) =>
                this.setState({
                  settings: { ...settings, ...recurringDateSettings },
                })
              }
              onCancel={() =>
                this.setState({
                  showUpdateRecurringSettingsModal: false,
                  submitAttempted: false,
                })
              }
              onSubmit={(updateSettings) =>
                this.setState(
                  {
                    settings: { ...settings, updateSettings },
                    bypassUpdateRecurringSettingsPrompt: true,
                  },
                  () => this.onSubmitSettings(),
                )
              }
              recurringDatesControls={this.getScheduleDatesControls(
                true,
                false,
              )}
              settings={settings}
            />
          ))}

        {/* Notify Students About Event Update Prompt */}
        {showNotifyStudentsAboutEventUpdateModal && (
          <ConfirmationModal
            cancel={() =>
              this.setState({ showNotifyStudentsAboutEventUpdateModal: false })
            }
            cancelText="No"
            confirm={() =>
              sendEventSmsMessage(
                eventScheduleID,
                rsvpMessageTypes.updatedEvent,
                actions,
              )
            }
            confirmText="Yes, send SMS message"
            message={
              "The event time, location or fee has been changed. " +
              "Would you like to notify your students about the change? " +
              "(Defaulted to include all Jewish students)"
            }
            show={showNotifyStudentsAboutEventUpdateModal}
            title="Notify Students?"
          />
        )}

        {/* Cancel Recurring Schedule Settings Prompt */}
        <CancelRecurringSettingsModal
          onCancel={() =>
            this.setState({ showCancelRecurringSettingsModal: false })
          }
          onSubmit={(updateSettings) => this.onCancelSchedule(updateSettings)}
          show={showCancelRecurringSettingsModal}
        />

        {/* Cancel Schedule Confirmation */}
        <ConfirmationModal
          cancel={() =>
            this.setState({ showCancelScheduleConfirmationModal: false })
          }
          cancelText="Keep schedule"
          confirm={() => this.onCancelSchedule()}
          confirmText="Cancel schedule"
          message="Are you sure you want to cancel this event schedule? This cannot be undone."
          show={showCancelScheduleConfirmationModal}
          title="Cancel Event Confirmation"
        />
        {showCloneRecurringWarningModal && (
          <ConfirmationModal
            cancel={() =>
              this.setState({ showCloneRecurringWarningModal: false })
            }
            cancelText="Back to settings"
            confirmText="Continue"
            confirm={() => {
              this.setState({ bypassCloneRecurringWarning: true }, () =>
                this.onSubmitSettings(),
              );
            }}
            message="You are cloning a recurring event. You may change your cloned event to a one time event on the Event Type Setting."
            show={true}
            title="Save cloned event?"
          />
        )}
      </>
    );
  }
}
