import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  cardContainer: {
    overflow: "visible",
    marginBottom: 24,
    padding: "1.25%",
    [theme.breakpoints.down("sm")]: {
      boxShadow: "none",
      "& .MuiCardContent-root": {
        padding: 0,
      },
    },
  },
  divider: {
    marginLeft: "36px",
    [theme.breakpoints.down("sm")]: {
      "&.MuiDivider-vertical": { height: 1, width: "100%" },
      "&.MuiDivider-middle": { margin: "16px 0px" },
    },
  },
  alert: {
    color: theme.palette.info.contrastText,
    backgroundColor: theme.palette.primary.light,
    marginBottom: 24,
    "& .MuiSvgIcon-root": {
      fill: theme.palette.info.contrastText,
    },
  },
  copyToClipboard: {
    display: "flex",
    alignItems: "center",
    "& input": {
      border: "none",
      width: 48,
      fontSize: "13px !important",
      color: "#747579",
    },
    "& input:focus": { outline: "none", border: "none" },
  },
  profileImage: {
    marginLeft: "16px",
    cursor: "pointer",
    transition: "opacity 300ms linear",
    "&:hover": {
      opacity: 0.6,
    },
  },
}));
