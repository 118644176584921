import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Collapse } from "@material-ui/core";
import { RaffleSelectors } from "../../../state";
import {
  EmbedWebPageInstructionText,
  EmbedWebPageIframeText,
} from "../raffleUtils";
export const WebpageSection = (props) => {
  const { showDonateURL = true, showAllExpanded } = props;

  const donorSiteURL = useSelector(RaffleSelectors.donorSiteURL);
  const chabadHouseDonateURL = useSelector(
    RaffleSelectors.chabadHouseDonateURL,
  );
  const chabadHouseID = useSelector(RaffleSelectors.chabadHouseID);
  const donorSiteIframeText = useSelector(RaffleSelectors.donorSiteIframeText);

  const [showWebPageExpanded, setShowWebPageExpanded] =
    useState(showAllExpanded);
  const handleWebPageToggleExpand = () => {
    setShowWebPageExpanded(!showWebPageExpanded);
  };

  const [showEmbedCodeExpanded, setShowEmbedCodeExpanded] =
    useState(showAllExpanded);
  const handleEmbedCodeToggleExpand = () => {
    setShowEmbedCodeExpanded(!showEmbedCodeExpanded);
  };

  const [showDonateUrlExpanded, setShowDonateUrlExpanded] =
    useState(showAllExpanded);
  const handleDonateUrlToggleExpand = () => {
    setShowDonateUrlExpanded(!showDonateUrlExpanded);
  };

  useEffect(() => {
    setShowWebPageExpanded(showAllExpanded);
    setShowEmbedCodeExpanded(showAllExpanded);
    setShowDonateUrlExpanded(showAllExpanded);
  }, [showAllExpanded]);

  return (
    <>
      <div className="raffles-form-section pointer-form-section">
        <div
          className="raffles-section-title"
          onClick={handleWebPageToggleExpand}
        >
          <div className="flex">
            <p className="large-text fw-900 flex flex-align-center pointer mb-8">
              Your Chabad House Grand Draw Web Page{" "}
              <span className="small-text fw-400 accent-text ml-4">
                (recommended)
              </span>
            </p>
          </div>
          {showWebPageExpanded ? (
            <i className="material-icons accent-text-secondary">
              keyboard_arrow_up
            </i>
          ) : (
            <i className="material-icons accent-text-secondary">
              keyboard_arrow_down
            </i>
          )}
        </div>
        <Collapse in={showWebPageExpanded}>
          <div className="expanded-section">
            <div className="raffles-link flex flex-align-center flex-justify-space mt-8">
              <p>{donorSiteURL}</p>
              <a
                href={donorSiteURL}
                target="_blank"
                rel="noopener noreferrer"
                className="link-text small-text flex flex-align-center ml-16 no-wrap"
              >
                <i className="material-icons medium-text mr-8">open_in_new</i>
                Go to Link
              </a>
            </div>
            {!!chabadHouseID && (
              <p className="small-text accent-text-dark mt-8">
                The URL Friendly Name (the part of the URL that references your
                Chabad House) can be updated in your Chabad House profile by
                clicking{" "}
                <a
                  className="link-text"
                  href={`/account`} //TODO scroll to chabad house section
                >
                  here
                </a>
              </p>
            )}
          </div>
        </Collapse>
      </div>
      <div className="raffles-form-section pointer-form-section container-width">
        <div
          className="raffles-section-title"
          onClick={handleEmbedCodeToggleExpand}
        >
          <div className="flex">
            <p className="large-text fw-900 flex flex-align-center pointer mb-8">
              Your web page code to embed{" "}
              <span className="small-text fw-400 accent-text ml-4">
                (advanced users)
              </span>
            </p>
          </div>
          {showEmbedCodeExpanded ? (
            <i className="material-icons accent-text-secondary">
              keyboard_arrow_up
            </i>
          ) : (
            <i className="material-icons accent-text-secondary">
              keyboard_arrow_down
            </i>
          )}
        </div>
        <Collapse in={showEmbedCodeExpanded}>
          <div className="expanded-section">
            <div className="mt-8">
              <EmbedWebPageInstructionText paragraphTextClass="accent-text-dark mb-16 line-height-double" />
            </div>
            <EmbedWebPageIframeText
              donorSiteIframeText={donorSiteIframeText}
              iframeStyle={{ height: "64px", lineHeight: "1.4" }}
              paragraphTextClass="accent-text-dark line-height-double mb-16"
            />
          </div>
        </Collapse>
      </div>
      {showDonateURL && !!chabadHouseID && (
        <div className="raffles-form-section pointer-form-section">
          <div
            className="raffles-section-title"
            onClick={handleDonateUrlToggleExpand}
          >
            <div className="flex">
              <p className="large-text fw-900 flex flex-align-center pointer mb-8">
                Donate URL
              </p>
            </div>
            {showDonateUrlExpanded ? (
              <i className="material-icons accent-text-secondary">
                keyboard_arrow_up
              </i>
            ) : (
              <i className="material-icons accent-text-secondary">
                keyboard_arrow_down
              </i>
            )}
          </div>
          <Collapse in={showDonateUrlExpanded}>
            <div className="expanded-section">
              <p className="accent-text-dark line-height-double mb-8 mt-8">
                This URL will be used on the closed Grand Draw page so users can
                donate directly to your Chabad House after the Grand Draw.
              </p>
              <p className="accent-text-dark line-height-double mb-8">
                Edit the URL in your Chabad House profile page by clicking{" "}
                <a
                  className="link-text"
                  href={`/account`} //TODO scroll to chabad house section
                >
                  here.
                </a>
              </p>
              {chabadHouseDonateURL ? (
                <p>{chabadHouseDonateURL}</p>
              ) : (
                <p className="accent-text italic-text">
                  Your Donate URL hasn't been set, we’ll use your main website
                  URL instead
                </p>
              )}
            </div>
          </Collapse>
        </div>
      )}
    </>
  );
};
