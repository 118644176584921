import React, { useState, useEffect, useMemo, useCallback, memo } from "react";
import { useSelector } from "react-redux";
import { Box } from "@material-ui/core";
import {
  getInteractionDataKey,
  getMetricDetailsAccessor,
  getEngagementPeriodLabel,
} from "./metricHelpers";
import InteractionGoalChartHeader from "./InteractionMetricChartHeader";
import InteractionsBarChart from "./InteractionsBarChart";
import NoMetrics from "./NoMetrics";
import ColoredLegendEntry from "../../ColoredLegendEntry";
import Loader from "../../../../components/Loader";
import { formatNumber, sum } from "../../../../lib";
import { EngagementSelectors, SystemSelectors } from "../../../../state";

function formatData(data, interactionCategories) {
  if (!data) return [];

  return interactionCategories
    .map((category) => category.id)
    .map((interactionCategoryID) => {
      const interactionCategoryData = {
        interactionCategoryID,
        total: 0,
      };

      data
        .filter(
          (goalMetrics) =>
            goalMetrics.interactionCategoryID === interactionCategoryID,
        )
        .forEach((goalMetrics) => {
          const { engagementPeriodID, goal, goalProgress, goalProgressExtra } =
            goalMetrics;
          const goalProgressPercentage = (goalProgress * 100) / goal;

          interactionCategoryData[getInteractionDataKey(engagementPeriodID)] =
            goalProgressPercentage > 100
              ? 100 //ensure that progress % does not exceed 100
              : goalProgressPercentage;

          interactionCategoryData[
            getMetricDetailsAccessor(engagementPeriodID)
          ] = goalMetrics;

          interactionCategoryData.total =
            interactionCategoryData.total + goalProgress + goalProgressExtra;
        });

      return interactionCategoryData;
    });
}

function InteractionGoalMetricsChart(props) {
  const { selectedEngagementPeriodIds, engagementPeriods, getColors } = props;

  const [formattedData, setFormattedData] = useState([]);
  const [interactionSettingFilter, setInteractionSettingFilter] = useState("");

  const { data, loading, errorMessage } = useSelector(
    EngagementSelectors.interactionGoalMetrics,
  );

  const interactionCategories =
    useSelector(SystemSelectors.interactionCategories) || []; // value is undefined on signout

  useEffect(
    function updateLocalData() {
      if (data) {
        //we are setting the data in local state per this issue where the bar labels do not appear on initial mount when reading directly from props https://github.com/recharts/recharts/issues/829
        //Note: This is still happening when an engagement period is first created
        setFormattedData(formatData(data, interactionCategories));
      }
    },
    [data, interactionCategories],
  );

  const formatTickY = useCallback(
    (key, data) => {
      const categoryDisplay =
        interactionCategories.find((c) => c.id === key)?.name || "";
      const total = data.find((d) => d.interactionCategoryID === key)?.total;

      return `${categoryDisplay}\nTotal: ${formatNumber(total)}`;
    },
    [interactionCategories],
  );

  const dataTotal = useMemo(
    () => sum(formattedData.map((d) => d.total)),
    [formattedData],
  );

  const rowHeight =
    selectedEngagementPeriodIds?.length * (interactionSettingFilter ? 20 : 30);
  const minRowHeight = 60;

  const chartHeight =
    formattedData.length *
    (rowHeight >= minRowHeight ? rowHeight : minRowHeight);

  return (
    <div className="graph-outline">
      <InteractionGoalChartHeader
        dataTotal={dataTotal}
        loading={loading}
        setInteractionSettingFilter={setInteractionSettingFilter}
        interactionSettingFilter={interactionSettingFilter}
        isGoalData
      />
      <div className="mt-16 mb-16">
        {loading ? (
          <Loader />
        ) : errorMessage ? (
          <p className="error-text small-text text-center">{errorMessage}</p>
        ) : !formattedData.length ? (
          <NoMetrics message="No engagement goal metrics found" />
        ) : (
          <>
            <Box className="flex flex-align-center mobile-block">
              {selectedEngagementPeriodIds?.map((id) => (
                <ColoredLegendEntry
                  getColors={getColors}
                  id={id}
                  name={getEngagementPeriodLabel(id, engagementPeriods)}
                  key={id}
                />
              ))}
            </Box>
            <InteractionsBarChart
              chartHeight={chartHeight}
              data={formattedData}
              tickYFormatter={(key) => formatTickY(key, formattedData)}
              metrics={selectedEngagementPeriodIds}
              interactionSettingFilter={interactionSettingFilter}
              getColors={getColors}
              isGoalData={true}
              tickYDataKey={"interactionCategoryID"}
              tickXFormatter={(key) => `${key}%`}
            />
          </>
        )}
      </div>
    </div>
  );
}

export default memo(InteractionGoalMetricsChart);
