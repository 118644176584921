import React from "react";

import { Navigation } from "../../lib";

import TripSidebar from "./sidebar/TripSidebar";
import StripeProvider from "../../components/StripeProvider";
import Students from "./students/Students";
import PegishaSettings from "./PegishaSettings";
import TravelTripSettings from "./TravelTripSettings";
import Metrics from "./metrics/Metrics";

const { REACT_APP_STRIPE_API_KEY } = process.env;

export default class MyTrip extends React.PureComponent {
  state = {
    tabContentShowing: "students",
  };

  async componentDidMount() {
    let { tab } = this.props.pageRoute.query;
    if (tab === undefined) {
      tab = "students";
      this.navigate(tab);
    } else {
      this.setState({ tabContentShowing: tab });
    }
  }

  componentWillUnmount() {
    this._ismounted = false;
  }

  componentDidUpdate(prevProps) {
    if (this.props.pageRoute !== prevProps.pageRoute) {
      const {
        query: { tab },
      } = this.props.pageRoute;
      const {
        query: { tab: prevTab },
      } = prevProps.pageRoute;

      if (tab !== prevTab) {
        this.setState({ tabContentShowing: tab });
      }
    }
  }

  navigate = (page) => {
    Navigation.go(`${this.props.pageRoute.location.pathname}?tab=${page}`);
  };

  render() {
    const { tabContentShowing } = this.state;
    const { actions, pageRoute, sys, trip } = this.props;
    return (
      <div
        className="flex tablet-block mobile-block container"
        style={{ alignItems: "flex-start" }}
      >
        <TripSidebar
          getHistoricTripEnrollments={actions.getHistoricTripEnrollments}
          getHistoricTrip={actions.getHistoricTrip}
          getTripSchedule={actions.getTripSchedule}
          showMenu={true}
          tabContentShowing={tabContentShowing}
          {...this.props}
        />
        {tabContentShowing === "students" ? (
          <Students
            getTripRegistrations={this.props.actions.getTripRegistrations}
            getTripRegistrationsForExport={
              actions.getTripRegistrationsForExport
            }
            getTripRegistrationDetails={actions.getTripRegistrationDetails}
            pageRoute={pageRoute}
            submitRecommendation={actions.submitTripRegistrationRecommendation}
            sys={sys}
            trip={trip}
            updateTripRegistration={actions.updateTripRegistration}
            updatedRegistration={trip.updatedRegistration}
          />
        ) : tabContentShowing === "settings" ? (
          trip.event.isTravelTrip ? (
            <TravelTripSettings {...this.props} />
          ) : (
            <StripeProvider apiKey={REACT_APP_STRIPE_API_KEY}>
              <PegishaSettings {...this.props} />
            </StripeProvider>
          )
        ) : tabContentShowing === "metrics" ? (
          <Metrics getTripMetrics={actions.getTripMetrics} trip={trip} />
        ) : null}
      </div>
    );
  }
}
