import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import Loader from "../../../../components/Loader";
import { formatNumber } from "../../../../lib";
import { EngagementSelectors, SystemSelectors } from "../../../../state";
import {
  getMetricDetailsAccessor,
  getEngagementPeriodLabel,
  StudentsMetricsChartHeader,
} from ".";
import StudentEngagementPeriodDetails from "./StudentEngagementPeriodDetails";
import MetricsBarChart from "./MetricsBarChart";
import NoMetrics from "./NoMetrics";
import JewishInterestLevelsLegend from "../../JewishInterestLevelsLegend";
import { getJewishInterestLevelColors } from "../shared";

function formatData(data, engagementPeriodIds) {
  if (!data) return [];
  return engagementPeriodIds.map((engagementPeriodID) => {
    const monthData = {
      engagementPeriodID,
      total: 0,
    };

    data
      .filter(
        (goalMetrics) => goalMetrics.engagementPeriodID === engagementPeriodID,
      )
      .forEach((goalMetrics) => {
        const { goal, goalProgress, goalProgressExtra, jewishInterestLevel } =
          goalMetrics;
        const goalProgressPercentage = (goalProgress * 100) / goal;

        monthData[jewishInterestLevel] =
          goalProgressPercentage > 100 ? 100 : goalProgressPercentage; //ensure that progress % does not exceed 100
        monthData[getMetricDetailsAccessor(jewishInterestLevel)] = goalMetrics;

        monthData.total += goalProgress + goalProgressExtra;
      });
    return monthData;
  });
}

const CustomTickY = ({
  tickProps,
  data,
  engagementPeriods,
  setEngagementPeriodDetailsId,
}) => {
  const { x, y, payload } = tickProps;
  const monthYearDisplay = getEngagementPeriodLabel(
    payload.value,
    engagementPeriods,
  );
  const total = data.find((d) => d.engagementPeriodID === payload.value)?.total;
  return (
    <g>
      <text
        x={x}
        y={y - 10}
        textAnchor="end"
        fill="rgba(0, 0, 0, 0.48)"
        className="fw-700"
      >
        {monthYearDisplay}
      </text>
      <text
        dx={total ? x - 50 : x}
        dy={y + 15}
        textAnchor="end"
        fill="rgba(0, 0, 0, 0.48)"
      >
        Total: {formatNumber(total)}{" "}
      </text>
      {!!total && (
        <text
          dx={x}
          dy={y + 15}
          textAnchor="end"
          fill="#2774ae"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setEngagementPeriodDetailsId(payload.value);
          }}
        >
          Details
        </text>
      )}
    </g>
  );
};

const StudentGoalMetricsChartByMonth = (props) => {
  const { engagementPeriodIds, engagementPeriods } = props;
  const { data, total, loading, errorMessage } = useSelector(
    EngagementSelectors.studentGoalMetrics,
  );

  const [formattedData, setFormattedData] = useState([]);
  const [engagementPeriodDetailsId, setEngagementPeriodDetailsId] = useState();
  const jewishInterestLevels = useSelector(
    SystemSelectors.engagementJewishInterestLevels,
  );

  useEffect(
    function updateLocalData() {
      if (data) {
        //we are setting the data in local state per this issue where the bar labels do not appear on initial mount when reading directly from props https://github.com/recharts/recharts/issues/829
        setFormattedData(formatData(data, engagementPeriodIds));
      }
    },
    [data, engagementPeriodIds],
  );

  const minRowHeight = 80;
  const chartHeight = formattedData.length * minRowHeight;

  return (
    <div className="graph-outline mb-24">
      <StudentsMetricsChartHeader loading={loading} dataTotal={total} />

      <div className="mt-8 mb-16">
        {loading ? (
          <Loader />
        ) : errorMessage ? (
          <p className="error-text small-text text-center">{errorMessage}</p>
        ) : !formattedData.length ? (
          <NoMetrics message="No student goal metrics found" />
        ) : (
          <>
            <JewishInterestLevelsLegend
              levelsSelector={SystemSelectors.engagementJewishInterestLevels}
            />
            <MetricsBarChart
              getColors={getJewishInterestLevelColors}
              groupByMonth={true}
              isGoalData={true}
              chartHeight={chartHeight}
              data={formattedData}
              tickYDataKey={"engagementPeriodID"}
              tickXFormatter={(key) => `${key}%`}
              metrics={jewishInterestLevels}
              tickComponent={(t) => (
                <CustomTickY
                  tickProps={t}
                  data={formattedData}
                  engagementPeriods={engagementPeriods}
                  setEngagementPeriodDetailsId={setEngagementPeriodDetailsId}
                />
              )}
            />
          </>
        )}
      </div>
      {engagementPeriodDetailsId && (
        <StudentEngagementPeriodDetails
          closeEngagmentPeriodDetails={() => setEngagementPeriodDetailsId()}
          engagementPeriodDetailsId={engagementPeriodDetailsId}
          engagementPeriods={engagementPeriods}
        />
      )}
    </div>
  );
};
export default React.memo(StudentGoalMetricsChartByMonth);
