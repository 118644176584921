import React from "react";
import { ProgramDetailsTab } from "./ProgramDetailsTab";
import { ResourceTab } from "./ResourceTab";
import Loader from "../../components/Loader";
import ProgramRegistrationButton from "./registration/ProgramRegistrationButton";
import ScrollMenu from "react-horizontal-scrolling-menu";
import {
  getCociLogo,
  IsApiErrorStatus,
  Navigation,
  PageLink,
  PageURL,
} from "../../lib";
import { withAppInsights } from "../../lib/AppInsights";
import moment from "moment";
import queryString from "query-string";

class ProgramDetails extends React.PureComponent {
  state = {
    isInitialScroll: true,
    isMounted: undefined,
    tabs: [
      {
        component: ProgramDetailsTab,
        id: "programDetails",
        title: "Program Details",
      },
      { component: ResourceTab, id: "resource", title: "Resources" },
    ],
  };

  componentDidMount() {
    this.getProgram();

    window.addEventListener("scroll", this.resizeHeaderOnScroll);
  }

  componentDidUpdate(prevProps) {
    const {
      location: { hash: routeHash },
      query: { m: routeQueryModal },
    } = this.props.pageRoute;

    if (prevProps.pageRoute.location.hash !== routeHash) {
      this.scrollToSection(
        routeHash.slice(1, routeHash.length),
        false,
        routeQueryModal,
      );
    }
  }

  componentWillUnmount() {
    //clear out program and program schedule from store when unmount to avoid duplicate mounting (prior to program/sched reload) when reaccessing the page
    this.props.actions.clearProgram();
    this.props.actions.clearProgramSchedule();
  }

  getProgram = async () => {
    const {
      location: { pathname },
      params: { programId },
      query: { sched },
    } = this.props.pageRoute;

    await this.props.actions.getProgram(programId, true);

    const { program } = this.props;

    if (!program || !program.details) {
      return;
    }

    let scheduleId;
    if (program.details.schedules && program.details.schedules.length) {
      if (
        sched &&
        program.details.schedules
          .map((s) => s.id.toString())
          .indexOf(sched.toString()) >= 0
      ) {
        scheduleId = sched;
      } else {
        scheduleId = program.details.schedules[0].id;
      }
    }

    if (scheduleId) {
      this.getSchedule(scheduleId, scheduleId !== sched); //maintain content section hash if persisting sched from query
    } else if (sched) {
      Navigation.redirect(pathname); //clear out irrelevant sched from query string
    }
  };

  getSchedule = async (scheduleId, clearContentSectionHash = true) => {
    const {
      params: { programId },
    } = this.props.pageRoute;

    await this.props.actions.getProgramSchedule(programId, scheduleId);

    const { schedule } = this.props;

    if (!schedule || !schedule.details) {
      return;
    }

    const {
      location: { hash: routeHash, pathname },
      query: { m: routeQueryModal, tab, folderId, fileId },
    } = this.props.pageRoute;
    let redirectUrl = `${pathname}?${queryString.stringify({
      sched: scheduleId,
      tab,
      folderId,
      fileId,
    })}`;
    if (routeHash && !clearContentSectionHash) {
      if (routeQueryModal) {
        redirectUrl += `&m=${routeQueryModal}`;
      }
      redirectUrl += routeHash;
    }
    Navigation.redirect(redirectUrl);

    if (routeHash && !clearContentSectionHash) {
      //toggle target content section open
      this.scrollToSection(
        routeHash.slice(1, routeHash.length),
        false,
        routeQueryModal,
      );
    } else {
      //default toggle first content section open
      const scheduleContents = schedule.details.contents
        ? schedule.details.contents.filter((c) => !c.isHidden)
        : [];
      if (scheduleContents && scheduleContents[0]) {
        this.toggleProgramSection(
          this.getContentSectionId(scheduleContents[0]),
        );
      }
    }

    this.setState({ isInitialScroll: false });
  };

  getContentSectionId = (contentSection) =>
    contentSection.name && contentSection.name.toLowerCase().replace(/ /g, "-");

  scrollToSection = (sectionId, redirect, modalName) => {
    const section = document.getElementById(sectionId);

    if (section) {
      if (!section.classList.contains("open")) {
        this.toggleProgramSection(sectionId);
      }

      setTimeout(() => {
        //setting 200ms timeout to ensure that scrolling happens only after open classname is toggled and UI updated
        const offset = section.offsetTop;
        const pageOffset =
          window.pageYOffset || document.documentElement.scrollTop;

        // Prev implementation accounting for parent offset.  Removed because was causing undesired behavior with too much scroll offset.
        // const parentOffset = document.getElementById(
        //   "program-details-page-container"
        // ).offsetTop;
        // const childOffset = section.offsetTop;
        // const offset = childOffset - parentOffset;
        // const pageOffset =
        //   window.pageYOffset || document.documentElement.scrollTop;

        if (pageOffset !== offset) {
          window.scrollTo({ behavior: "smooth", left: 0, top: offset });

          if (redirect) {
            const {
              location: { pathname },
              query,
            } = this.props.pageRoute;

            var redirectUrl = `${pathname}?${queryString.stringify({
              ...query,
              m: modalName,
            })}`;
            redirectUrl += `#${sectionId}`;
            Navigation.redirect(redirectUrl);
          } else if (modalName) {
            this.setState({ [modalName]: true });
          }
        }
      }, 200);
    }
  };

  // viewProgram = () => {
  //   const viewedPrograms =
  //     JSON.parse(localStorage.getItem('viewedPrograms')) || [];
  //   const { programId } = this.props.pageRoute.params;
  //   viewedPrograms.push(programId);
  //   localStorage.setItem('viewedPrograms', JSON.stringify(viewedPrograms));
  // };

  resizeHeaderOnScroll = () => {
    const distance = window.scrollY || document.documentElement.scrollTop;
    const shrinkOn = 250;
    const header = document.getElementById("program-details-header");
    if (header) {
      if (distance > shrinkOn) {
        if (!header.classList.contains("smaller"))
          header.classList.add("smaller");
      } else {
        if (header.classList.contains("smaller"))
          header.classList.remove("smaller");
      }
    }
  };

  formatProgramDates = (schedule) => {
    let startDate = schedule && schedule.programStartDate;
    let endDate = schedule && schedule.programEndDate;
    let startMonth, startDay, startYear, endMonth, endDay, endYear, dates;
    if (startDate) {
      startMonth = new Date(startDate).toLocaleDateString("en-us", {
        month: "short",
      });
      startDay = new Date(startDate).toLocaleDateString("en-us", {
        day: "2-digit",
      });
      startYear = new Date(startDate).toLocaleDateString("en-us", {
        year: "numeric",
      });
    }
    if (endDate) {
      endMonth = new Date(endDate).toLocaleDateString("en-us", {
        month: "short",
      });
      endDay = new Date(endDate).toLocaleDateString("en-us", {
        day: "2-digit",
      });
      endYear = new Date(endDate).toLocaleDateString("en-us", {
        year: "numeric",
      });
    }
    dates =
      startMonth +
      " " +
      startDay +
      ", " +
      startYear +
      " - " +
      endMonth +
      " " +
      endDay +
      ", " +
      endYear;
    return dates;
  };

  renderApplicationButton = (audience, schedule, jotformQueryString) => {
    const {
      allowMultipleRegistrations,
      id: programId,
      name,
      shliachBaseUrl,
    } = (this.props.program && this.props.program.details) || {};
    let {
      actionButtonText,
      id,
      preRegisterStartDate,
      registerStartDate,
      registerEndDate,
      programStartDate,
      programEndDate,
      preRegisterURL,
      registerURL,
      registrationMethod,
      isOngoing,
      includesShliach,
      isShliachRegistered,
    } = schedule || {};

    // TODO: We have an issue around the fact that for 'Advanced' programs we have no indication of whether the Shliach enrolled yet or not
    // The includesShliach flag does NOT indicate if the Shliach enrolled, it only indicates if the program is available to the Shliach
    // We are therefore currently ALWAYS showing the 'View My' button for Advanced programs that are available to a (even unenrolled) Shliach
    // FOR NOW - for Life Insurance program purposes only - we are hard-coding the button text to display Register text
    // The Register text will show EVEN IF the Shliach already enrolled in the program
    const isLifeInsuranceProgram = id === 336;

    //'Advanced' programs with internal program pages: if includes shliach show button linking to Manage Program page
    if (shliachBaseUrl && includesShliach) {
      return (
        <PageLink
          className="btn btn-accent apply-btn full-width mt-16"
          to={`${shliachBaseUrl}/${id}`}
        >
          {/* TEMPORARILY accommodating Life Insurance program registration: */}
          {isLifeInsuranceProgram
            ? actionButtonText || `Register for ${name}`
            : `View My ${name}`}
          {/* View My {name} */}
        </PageLink>
      );
    }

    //'Basic' programs with no internal program pages: if shliach is not included don't show registration button
    if (!shliachBaseUrl && !includesShliach) {
      return "";
    }

    //if already registered and program does not allow multiple registrations, don't show registration button
    if (!allowMultipleRegistrations && isShliachRegistered) {
      return "";
    }

    //registration button logic
    preRegisterStartDate = preRegisterStartDate
      ? new Date(preRegisterStartDate)
      : null;
    registerStartDate = registerStartDate ? new Date(registerStartDate) : null;
    registerEndDate = new Date(registerEndDate);
    programStartDate = new Date(programStartDate);
    programEndDate = new Date(programEndDate);
    const today = new Date();

    let registrationButtonURL;
    let isJotformRegistration;

    if (
      registerURL && //has register url
      (isOngoing || //and schedule is ongoing
        (registerStartDate <= today && registerEndDate >= today) || //or within registration dates
        (!registerStartDate && //or registration dates not defined but within program dates
          programStartDate <= today &&
          programEndDate >= today))
    ) {
      isJotformRegistration =
        registrationMethod === "Jotform" &&
        registerURL.indexOf("form.jotform.com") >= 0;
      //append jotform query string to registration jotform url
      if (isJotformRegistration) {
        registerURL += jotformQueryString;
      }

      registrationButtonURL = registerURL;
    }
    //if registrationButtonURL hasn't been set to registerURL, check for preRegisterURL
    else if (
      preRegisterURL && //has pre-register url
      preRegisterStartDate &&
      preRegisterStartDate <= today //and past pre-register start date
    ) {
      isJotformRegistration = preRegisterURL.indexOf("form.jotform.com") >= 0;
      //append jotform query string to registration jotform url
      if (isJotformRegistration) {
        preRegisterURL += jotformQueryString;
      }

      registrationButtonURL = preRegisterURL;
    }

    if (registrationButtonURL) {
      const buttonText = actionButtonText || `Register for ${name}`;
      if (isJotformRegistration) {
        return (
          <ProgramRegistrationButton
            allowMultipleRegistrations={allowMultipleRegistrations}
            buttonText={buttonText}
            pageRoute={this.props.pageRoute}
            programId={programId}
            registrationURL={registrationButtonURL}
            scheduleId={id}
            shliachID={this.props.shliachID}
          />
        );
      }

      //open registration link in same tab if it's an internal link
      const openInSameTab =
        registrationButtonURL.indexOf(window.location.origin) === 0;

      return (
        <a
          className="btn btn-accent apply-btn full-width mt-16 whitespace-normal"
          href={registrationButtonURL}
          rel="noopener noreferrer"
          style={{
            lineHeight: "normal",
            textAlign: "center",
          }}
          target={openInSameTab ? undefined : "_blank"}
        >
          {buttonText}
        </a>
      );
    }

    return "";
  };

  toggleProgramSection = (sectionId) => {
    let section = document.getElementById(sectionId);
    if (section) {
      section.classList.toggle("open");
    }
  };

  setTagColor = (tag) => {
    let background;
    switch (tag) {
      case "Education":
        background = "#FFC600";
        break;
      case "Trips":
        background = "#2774AE";
        break;
      case "Grants":
        background = "#9AE19D";
        break;
      case "Events":
        background = "#537A5A";
        break;
      case "Funding":
        background = "#9ED0E6";
        break;
      case "Programs":
        background = "#4ECDC4";
        break;
      case "Student Engagement":
        background = "#416b8c";
        break;
      case "Shlichus Advancement":
        background = "#FF6B6B";
        break;
      case "Shluchim-Shluchos Support":
        background = "#AC3931";
        break;
      case "General":
        background = "#E9AFA3";
        break;
      case "Other":
        background = "#533B4D";
        break;
      case "Local Programs":
        background = "#B796AC";
        break;
      default:
        background = "#1E1C1C";
    }
    return background;
  };

  getCurrentTab = () => {
    const {
      pageRoute: {
        query: { tab: tabId },
      },
    } = this.props;
    const { tabs } = this.state;

    if (!tabId) return tabs[0];

    return tabs.find((t) => t.id === tabId) || tabs[0];
  };

  toTab = (tabId, resourcesFolderID) => {
    const {
      page,
      params,
      query: { sched },
    } = this.props.pageRoute;
    const queryParams = { tab: tabId, sched };
    if (tabId === "resource") {
      queryParams.folderId = resourcesFolderID;
    }
    const url = PageURL.to(page, params, queryParams);
    Navigation.redirect(url);
    //for full screen width where tabs are at the bottom of the window, scroll to top of page when accessing new tab
    if (window.innerWidth > 720) {
      window.scrollTo(0, 0);
    }
  };

  render() {
    const { isInitialScroll, tabs } = this.state;

    const {
      pageRoute,
      program: { details: programDetails, error, loading, success },
      schedule: {
        details: scheduleDetails,
        loading: loadingSchedule,
        success: successSchedule,
      },
    } = this.props;

    const {
      query: { sched },
    } = pageRoute || {};

    const {
      audience,
      category,
      description,
      id,
      jotformQueryString,
      name,
      resourcesFolderID,
      schedules,
      url,
    } = programDetails || {};

    const {
      contents,
      email,
      id: scheduleId = sched, //default to url sched id query param for pre-load selection
      officeStaff,
      marketingMaterialURL,
      programImageURL,
      programStartDate,
      programEndDate,
      hebrewProgramStartDate,
      hebrewProgramEndDate,
      isOngoing,
    } = scheduleDetails || {};

    const dates = this.formatProgramDates(scheduleDetails);

    const scheduleContents = contents
      ? contents.filter((c) => !c.isHidden)
      : [];

    const tab = this.getCurrentTab();

    return (
      <>
        {loading ? (
          <div className="full-page-loader">
            <Loader />
          </div>
        ) : success === false ? (
          <div className="text-center mt-48">
            <img
              src="/images/error.svg"
              alt="error-robot"
              height="320"
              style={{ maxWidth: "100%" }}
            />
            <p
              className="text-center error-message mt-24"
              style={{ position: "relative" }}
            >
              {error && IsApiErrorStatus(error, 404)
                ? "Sorry, this program is not available."
                : "Something went wrong and we could not retrieve Program Details. Please try again."}
            </p>
          </div>
        ) : (
          programDetails && (
            <React.Fragment>
              <div
                className="program-details-header"
                id="program-details-header"
              >
                <div className="program-details-header-overlay" />
                <img
                  src={programImageURL ? programImageURL : getCociLogo()}
                  alt="background"
                />
                <div className="program-details-header-text mt-32">
                  {this.props.pageRoute.location.key && (
                    <p
                      className="fw-700 flex flex-align-center back-to-programs"
                      onClick={this.props.pageRoute.history.goBack}
                    >
                      <i className="material-icons mr-8 xl-text">arrow_back</i>
                      <span>Go Back</span>
                    </p>
                  )}
                  <h3 className="xxxl-text fw-700">{name}</h3>
                  <span className="title program-schedule-title">
                    {scheduleDetails ? scheduleDetails.title : ""}
                  </span>

                  {schedules && schedules.length > 0 && (
                    <ScrollMenu
                      alignCenter={false}
                      arrowLeft={
                        <div className="flex flex-justify-center">
                          <i className="material-icons medium-text link-text-secondary mr-8">
                            keyboard_arrow_left
                          </i>
                        </div>
                      }
                      arrowRight={
                        <div className="flex flex-justify-center">
                          <i className="material-icons medium-text link-text-secondary ml-8">
                            keyboard_arrow_right
                          </i>
                        </div>
                      }
                      data={schedules.map(({ id, programStartDate, title }) => (
                        <div
                          className="menu-item link-text-secondary uppercase-text text-center"
                          key={id}
                        >
                          {title ||
                            `${name} ${
                              programStartDate
                                ? moment(programStartDate).format("M/D/YY")
                                : ""
                            }`}
                        </div>
                      ))}
                      hideArrows={true}
                      hideSingleArrow={true}
                      onSelect={loadingSchedule ? null : this.getSchedule}
                      scrollToSelected={isInitialScroll}
                      selected={scheduleId ? scheduleId.toString() : ""}
                      wheel={false} //disable scroll with mouse scroll
                    />
                  )}
                </div>
              </div>
              <div
                className="page program-details-page container relative"
                id="program-details-page-container"
              >
                {loadingSchedule && (
                  <div className="overlay-loader">
                    <Loader />
                  </div>
                )}
                {!loadingSchedule && successSchedule === false ? (
                  <div className="text-center full-width">
                    <img
                      src="/images/error.svg"
                      alt="error-robot"
                      height="320"
                      style={{ maxWidth: "100%" }}
                    />
                    <p
                      className="text-center error-message mt-24"
                      style={{ position: "relative" }}
                    >
                      Something went wrong and we could not retrieve Program
                      Schedule Details. Please try again.
                    </p>
                  </div>
                ) : (
                  scheduleDetails && (
                    <>
                      <div
                        className="program-details-sidebar"
                        id="program-details-sidebar"
                      >
                        <div className="card mb-24">
                          <div className="program-tags">
                            <div
                              className="tag medium program-tag-orange"
                              style={{
                                backgroundColor: this.setTagColor(
                                  audience && audience.displayValue,
                                ),
                                color: "#fff",
                              }}
                            >
                              {audience && audience.displayValue}
                            </div>

                            <div
                              className={`tag medium program-tag-green`}
                              style={{
                                backgroundColor: this.setTagColor(
                                  category && category.displayValue,
                                ),
                                color: "#fff",
                              }}
                            >
                              {category && category.displayValue}
                            </div>
                          </div>
                          <div className="mb-16">
                            <label>Description</label>
                            <p className="line-height-double">{description}</p>
                          </div>
                          {!isOngoing ? (
                            <div className="program-dates">
                              <label>Dates Running</label>
                              {programStartDate && programEndDate ? (
                                <React.Fragment>
                                  <p className="flex flex-align-center mb-8">
                                    <i className="material-icons mr-8 large-text">
                                      calendar_today
                                    </i>
                                    <span className="mr-4">
                                      {hebrewProgramStartDate}
                                      {" -"}
                                    </span>
                                    <span>{hebrewProgramEndDate}</span>
                                  </p>
                                  <p className="flex flex-align-center">
                                    <i className="material-icons mr-8 large-text">
                                      calendar_today
                                    </i>
                                    <span>{dates}</span>
                                  </p>
                                </React.Fragment>
                              ) : (
                                <p className="flex flex-align-center">
                                  <i className="material-icons mr-8 large-text">
                                    calendar_today
                                  </i>
                                  <span>TBD</span>
                                </p>
                              )}
                            </div>
                          ) : null}
                          {officeStaff ? (
                            <div className="program-coordinator mb-16">
                              <label>Program Coordinator</label>
                              <p>{officeStaff && officeStaff.fullName}</p>
                            </div>
                          ) : null}
                          <div className="program-contact-info">
                            {officeStaff ? (
                              <React.Fragment>
                                {officeStaff.primaryPhone ? (
                                  <p>
                                    <i className="material-icons">phone</i>
                                    <a
                                      href={`tel:${officeStaff.primaryPhone}, ${officeStaff.primaryPhoneExt}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {officeStaff.primaryPhone}
                                      {officeStaff &&
                                      officeStaff.primaryPhoneExt ? (
                                        <span className="ml-8 small-text">
                                          Ext: {officeStaff.primaryPhoneExt}
                                        </span>
                                      ) : null}
                                    </a>
                                  </p>
                                ) : null}
                                <p>
                                  <i className="material-icons">email</i>
                                  <a
                                    href={`mailto:${
                                      email ? "email" : officeStaff.primaryEmail
                                    }`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {email
                                      ? email
                                      : officeStaff && officeStaff.primaryEmail}
                                  </a>
                                </p>
                              </React.Fragment>
                            ) : null}
                            {marketingMaterialURL ? (
                              <p>
                                <i className="material-icons">save_alt</i>
                                <a
                                  className="link-text-secondary"
                                  href={marketingMaterialURL}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Download marketing material
                                </a>
                              </p>
                            ) : null}
                            {url ? (
                              <p>
                                <i className="material-icons">language</i>
                                <a
                                  className="link-text"
                                  href={
                                    url && url.indexOf("form.jotform.com") >= 0
                                      ? url + jotformQueryString
                                      : url
                                  }
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {url
                                    .replace(/^https?:\/\//i, "")
                                    .replace(/\/$/, "")}
                                </a>
                              </p>
                            ) : null}
                          </div>
                          {this.renderApplicationButton(
                            audience && audience.displayValue,
                            scheduleDetails,
                            jotformQueryString,
                          )}
                        </div>
                        <div className="card program-tabs">
                          {tabs.map(
                            (t) =>
                              (t.id !== "resource" || !!resourcesFolderID) && (
                                <p
                                  className={`fw-700 program-tab-link link-text-secondary tablet-mr-16 mobile-mr-16 ${
                                    tab && tab.id === t.id ? "active" : ""
                                  }`}
                                  key={t.id}
                                  onClick={() =>
                                    this.toTab(t.id, resourcesFolderID)
                                  }
                                >
                                  {t.title}
                                </p>
                              ),
                          )}
                        </div>
                      </div>
                      <div className="program-details-overview card">
                        {tab &&
                          React.createElement(tab.component, {
                            pageRoute,
                            ...(tab.component === ProgramDetailsTab
                              ? {
                                  scheduleContents,
                                  jotformQueryString,
                                  state: this.state,
                                  setState: (name, value) =>
                                    this.setState({ [name]: value }),
                                  scrollToSection: this.scrollToSection,
                                  getContentSectionId: this.getContentSectionId,
                                  toggleProgramSection:
                                    this.toggleProgramSection,
                                }
                              : tab.component === ResourceTab
                              ? {
                                  id,
                                  resourcesFolderID,
                                  programName: name,
                                }
                              : {}),
                          })}
                      </div>
                    </>
                  )
                )}
              </div>
            </React.Fragment>
          )
        )}
      </>
    );
  }
}

export default withAppInsights(ProgramDetails);
