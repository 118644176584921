import {
  JewishInterestLevel,
  JewishInterestLevels,
  JewishObservanceStatuses,
} from "../../../state/engagement/types";

const navy = {
  dark: "#2774AE",
  light: "rgba(39,116,174,0.08)",
  text: "#FFFFFF",
};
const yellow = {
  dark: "#FFC602",
  light: "rgba(255,198,0,0.08)",
  text: "#1E1C1C",
};
const salmon = {
  dark: "#FF8585",
  light: "rgba(255,107,107,0.08)",
  text: "#1E1C1C",
};
const seaGreen = {
  dark: "#77D9D2",
  light: "rgba(154,225,157,0.08)",
  text: "#1E1C1C",
};
const orange = {
  dark: "#FFAD71",
  light: "rgba(172,57,49,0.08)",
  text: "#1E1C1C",
};
const purple = {
  dark: "#9389F1",
  light: "rgba(183,150,172,0.08)",
  text: "#FFFFFF",
};
const lightBlue = {
  dark: "#7CABCF",
  light: "rgba(124,171,207,0.08)",
  text: "#1E1C1C",
};
const darkNavy = {
  dark: "#124870",
  light: "rgba(18,72,112,0.08)",
  text: "#FFFFFF",
};
const gray = {
  dark: "#E0E0E0",
  light: "rgba(0,0,0,0.87)",
  text: "#FFFFFF",
};

export const palette = [
  lightBlue,
  orange,
  yellow,
  seaGreen,
  purple,
  navy,
  salmon,
  darkNavy,
];

export function getJewishInterestLevelColors(
  interestLevel: JewishInterestLevel,
) {
  switch (interestLevel) {
    case JewishInterestLevels.New:
      return lightBlue;
    case JewishInterestLevels.Uninterested:
      return orange;
    case JewishInterestLevels.Interested:
      return yellow;
    case JewishInterestLevels.Connected:
      return seaGreen;
    case JewishInterestLevels.Immersed:
      return purple;
    default:
      return gray;
  }
}
export function getJewishObservanceStatusColor(
  observanceType: JewishObservanceStatuses,
) {
  switch (observanceType) {
    case JewishObservanceStatuses.Absent:
      return salmon;
    case JewishObservanceStatuses.Ethnic:
      return lightBlue;
    case JewishObservanceStatuses.Cultural:
      return yellow;
    case JewishObservanceStatuses.Traditional:
      return seaGreen;
    case JewishObservanceStatuses.Observant:
      return purple;
    default:
      return gray;
  }
}

const monthsOfTheYear = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export function formatMonthYear(date: Date | string) {
  if (!(date instanceof Date)) {
    date = new Date(date);
  }
  var month = monthsOfTheYear[date.getUTCMonth()];
  var year = date.getUTCFullYear();
  return `${month} ${year}`;
}

/**
 * Use the middle of the month instead of the 1st, so the value (date.toISOString())
 * will be in the correct month, regardless of the timezone.
 * (The BE only looks at the month and year, and then gets the first and last moments of that month.)
 */
export const getDateForMonthYearSelect = (year: number, month: number) =>
  new Date(year, month, 15).toISOString();

export function getValuesForMonthsYearsSelect(numberOfMonths: number) {
  const now = new Date();
  const monthYearsArray = [];
  for (var i = 0; i <= numberOfMonths; i++) {
    const date = getDateForMonthYearSelect(
      now.getFullYear(),
      now.getMonth() - i,
    );

    monthYearsArray.push({
      value: date,
      label: formatMonthYear(date),
    });
  }
  return monthYearsArray;
}

export const InteractionSettingTypes = {
  Individual: "Individual",
  Communal: "Communal",
};

export const getStudentListColumnLayout = (
  showJewishInterestLevel: boolean,
) => {
  let gridTemplateColumns = "1fr 2.5fr 1fr 1fr 2fr ";
  if (showJewishInterestLevel) {
    gridTemplateColumns += "1fr ";
  }
  gridTemplateColumns += "30px 30px 30px 30px";
  return gridTemplateColumns;
};
